import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import { SetMindflickOrganisationAdmin } from 'src/app/+state/super-admin/super-admin.actions';
import { selectMindflickOrganisationUsers } from 'src/app/+state/super-admin/super-admin.selector';
import { SelectValue } from 'src/app/shared/models';
import { faCloseSVG } from 'src/icons';

@Component({
  selector: 'app-edit-organisation-admin-modal',
  templateUrl: './edit-organisation-admin-modal.component.html',
  styleUrls: ['./edit-organisation-admin-modal.component.scss']
})
export class EditOrganisationAdminModalComponent implements OnInit {
  users$ = this.store.select(selectMindflickOrganisationUsers);

  mindflickOrganisationId: number = 0;

  users: SelectValue[] = [];
  selectedUserAk: number = 0;

  updateAccountAdmins = true;

  closeIcon = faCloseSVG;

  destroyed$ = new Subject<boolean>();

  constructor(private store: Store<AppState>, private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.users$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(users => {
        this.users = users.map(u => ({
          value: u.ak,
          description: u.firstName + ' ' + u.lastName
        }));
      });
  }

  close() {
    this.modalRef.hide();
  }

  saveChanges() {
    if (this.mindflickOrganisationId && this.selectedUserAk)
      this.store.dispatch(SetMindflickOrganisationAdmin.Request({ organisationId: this.mindflickOrganisationId, userAk: this.selectedUserAk, updateAccountAdmins: this.updateAccountAdmins, modalId: this.modalRef.id }));
  }
}
