import { Component, Input, inject } from '@angular/core';
import { AccountAdminService } from '../../../shared/services/account-admin.service';
import { take } from 'rxjs';
import { LiveAnalyticsNudgeDTO } from '../../../shared/models/live-analytics/top-live-analytics-nudge-dto.interface';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NudgeDetailsModalComponent } from '../../../shared/components/nudge-details-modal/nudge-details-modal.component';
import { GrowthType } from '../../../+state/growth/growth.reducer';
import { LiveAnalyticsWorkOnDTO } from '../../../shared/models/live-analytics/live-analytics-work-on-dto.interface';
import { ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-la-nudges',
  templateUrl: './la-nudges.component.html',
  styleUrl: './la-nudges.component.scss'
})
export class LaNudgesComponent {
  accountAdminService = inject(AccountAdminService);
  modalService = inject(BsModalService);

  _filter?: string;
  @Input()
  get filter(): string | undefined {
    return this._filter;
  }

  set filter(val: string | undefined) {
    this._filter = val;

    if (this._filter) {
      this.loadResults();
    }
  }

  _mindflickAccountId?: number;
  @Input()
  get mindflickAccountId(): number | undefined {
    return this._mindflickAccountId;
  }

  set mindflickAccountId(val: number | undefined) {
    this._mindflickAccountId = val;

    if (this._mindflickAccountId) {
      this.loadResults();
    }
  }

  topNudges: LiveAnalyticsNudgeDTO[] = []
  topWorkOns: LiveAnalyticsWorkOnDTO[] = []
  nudgesSeen = 0;
  nudgeUsefulPercent = [{name: "", value: 0}];

  GrowthType = GrowthType;

  allOrangeScheme = {
    domain: ['#C699F1'],
    name: '',
    selectable: false,
    group: ScaleType.Ordinal,
  };

  loadResults() {
    if (this.mindflickAccountId && this.filter) {
      this.accountAdminService
        .getTopNudgesLiveAnalytics(this.mindflickAccountId, this.filter)
        .pipe(take(1))
        .subscribe(nudges => {
          this.topNudges = nudges;
        });

      this.accountAdminService
        .getTopWorkOnsLiveAnalytics(this.mindflickAccountId, this.filter)
        .pipe(take(1))
        .subscribe(nudges => {
          this.topWorkOns = nudges;
        });

      this.accountAdminService
        .getNudgesSeenLiveAnalytics(this.mindflickAccountId, this.filter)
        .pipe(take(1))
        .subscribe(count => {
          this.nudgesSeen = count;
        });

      this.accountAdminService
        .getNudgesUsefulPercentLiveAnalytics(this.mindflickAccountId, this.filter)
        .pipe(take(1))
        .subscribe(usefulPercent => {
          this.nudgeUsefulPercent = [{ name: '', value: usefulPercent }];
        });
    }
  }

  showNudgeDetails(nudge: LiveAnalyticsNudgeDTO) {
    this.modalService.show(NudgeDetailsModalComponent, {
      initialState: {
        nudge: nudge,
      },
    });
  }

  trackNudge(_: number, nudge: LiveAnalyticsNudgeDTO) {
    return nudge.nudgeId;
  }

  trackWorkOn(_: number, workOn: LiveAnalyticsWorkOnDTO) {
    return workOn.id;
  }

  formatGaugeValue(data: string) {
    return Math.round(Number(data)) + '%';
  }
}
