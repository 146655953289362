import { Component, inject, Input } from '@angular/core';
import { ScaleType, LegendPosition } from '@swimlane/ngx-charts';
import { take } from 'rxjs';
import { LiveAnalyticsProperty } from 'src/app/shared/models/live-analytics/live-analytics-graph.interface';
import { AccountAdminService } from 'src/app/shared/services/account-admin.service';

@Component({
  selector: 'app-la-connected-profile-views',
  templateUrl: './la-connected-profile-views.component.html',
  styleUrl: './la-connected-profile-views.component.scss'
})
export class LaConnectedProfileViewsComponent {
  accountAdminService = inject(AccountAdminService);

  _filter?: string;
  @Input() 
  get filter(): string | undefined {
    return this._filter;
  }

  set filter(val: string | undefined) {
    this._filter = val;

    if (this._filter) {
      this.loadResults();
      this.yAxisLabel = this.filterNameMap.get(this._filter) ?? '';
    }
  }

  _mindflickAccountId?: number;
  @Input()
  get mindflickAccountId(): number | undefined {
    return this._mindflickAccountId;
  }

  set mindflickAccountId(val: number | undefined) {
    this._mindflickAccountId = val;

    if (this._mindflickAccountId) {
      this.loadResults();
    }
  }

  yAxisLabel = ''; 

  filterNameMap = new Map<string, string>([
    [ 'M', 'Month' ],
    [ 'Q', 'Quarter' ],
    [ 'Y', 'Year' ]
  ]);
  
  results?: LiveAnalyticsProperty[];
  resultsLoading: boolean = true;

  errorMessage?: string;

  horizontalBarScheme = {
    domain: ['#C699F1'],
    name: '',
    selectable: false,
    group: ScaleType.Ordinal,
  };

  LegendPosition = LegendPosition;

  loadResults() {
    if (this.mindflickAccountId && this.filter) {
      this.resultsLoading = true;
      this.accountAdminService.getConnectedPageViewsByTimePeriodLiveAnalytics(this.mindflickAccountId, this.filter)
        .pipe(
          take(1),
        )
        .subscribe(resp => {
          this.results = resp;
          this.resultsLoading = false;
        });
    }
      
  }

  formatBarXAxis(val: any) {
    if (val % 1 != 0) {
      return undefined;
    }
    return val;
  };
}
