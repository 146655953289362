import { Component, Input, inject } from '@angular/core';
import { LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { faCollapseSVG, faDropdownSVG } from '../../../../icons';
import { FeatureUsageBreakdownItem } from '../../../shared/models/live-analytics/feature-usage-breakdown-item.interface';
import { ReportSeries } from '../../../shared/models/super-admin-report.interface';
import { AccountAdminService } from '../../../shared/services/account-admin.service';
import { LiveAnalyticsProperty } from '../../../shared/models/live-analytics/live-analytics-graph.interface';

@Component({
  selector: 'app-la-feature-usage',
  templateUrl: './la-feature-usage.component.html',
  styleUrl: './la-feature-usage.component.scss'
})
export class LaFeatureUsageComponent {
  accountAdminService = inject(AccountAdminService);

  _filter?: string;
  @Input()
  get filter(): string | undefined {
    return this._filter;
  }

  set filter(val: string | undefined) {
    this._filter = val;

    if (this._filter) {
      this.loadResults();
    }
  }

  _mindflickAccountId?: number;
  @Input()
  get mindflickAccountId(): number | undefined {
    return this._mindflickAccountId;
  }

  set mindflickAccountId(val: number | undefined) {
    this._mindflickAccountId = val;

    if (this._mindflickAccountId) {
      this.loadResults();
    }
  }

  results?: ReportSeries[];

  breakdownResults?: FeatureUsageBreakdownItem[];


  resultsLoading: boolean = true;

  errorMessage?: string;

  combinedColorScheme = {
    domain: ['#C699F1', '#FDAE7F', '#DADBDA'],
    name: '',
    selectable: false,
    group: ScaleType.Ordinal,
  };
  breakdownSchemes = [

    {
      domain: ['#C699F1'],
      name: '',
      selectable: false,
      group: ScaleType.Ordinal,
    }, {
      domain: ['#FDAE7F'],
      name: '',
      selectable: false,
      group: ScaleType.Ordinal,
    },
    {
      domain: ['#DADBDA'],
      name: '',
      selectable: false,
      group: ScaleType.Ordinal,
    }];

  isFeatureUsageExpanded = false;

  dropdownIcon = faDropdownSVG;
  collapseIcon = faCollapseSVG;

  LegendPosition = LegendPosition;

  loadResults() {
    if (this.mindflickAccountId && this.filter) {
      zip(
        this.accountAdminService.getLiveAnalyticsStrengthsSpotted(this.mindflickAccountId!, this.filter!).pipe(take(1)),
        this.accountAdminService.getLiveAnalyticsMeetingsCreated(this.mindflickAccountId!, this.filter!).pipe(take(1)),
        this.accountAdminService.getLiveAnalyticsPincodesCreated(this.mindflickAccountId!, this.filter!).pipe(take(1)),
      )
        .subscribe(([strengthsSpotted, meetingsCreated, pincodesCreated]) => {

          this.breakdownResults = [{
            title: 'Strength spotting',
            description: 'Number of strengths spotted',
            data: []
          },
          {
            title: 'Team meetings',
            description: 'Number of meetings held',
            data: [
            ]
          },

          {
            title: 'Pin codes',
            description: 'Number of pin codes created',
            data: [
            ]
          },

          ];

          const EARLIEST_DATE_INDEX = this.findEarliestNonZeroDate([strengthsSpotted, meetingsCreated, pincodesCreated])

          this.breakdownResults[0].data = strengthsSpotted.slice(EARLIEST_DATE_INDEX);
          this.breakdownResults[1].data = meetingsCreated.slice(EARLIEST_DATE_INDEX);;
          this.breakdownResults[2].data = pincodesCreated.slice(EARLIEST_DATE_INDEX);;


          this.results = [];

          for (let i = 0; i < this.breakdownResults[0].data.length; i++) {
            this.results.push({
              name: this.breakdownResults[0].data[i].name,
              series: [
                {
                  name: "Strength spotting",
                  value: this.breakdownResults[0].data[i]?.value ?? 0
                },
                {
                  name: "Team meetings",
                  value: this.breakdownResults[1].data[i]?.value ?? 0
                },
                {
                  name: "Pin codes",
                  value: this.breakdownResults[2].data[i]?.value ?? 0
                },
              ]
            });
          }
          this.resultsLoading = false;
        });

      this.resultsLoading = true;
    }

  }


  findEarliestNonZeroDate(data: LiveAnalyticsProperty[][]) {
    for (let i = 0; i < data[0].length; i++) {
      if (data.some(dataset => dataset[i].value !== 0)) {
        return i; 
      }
    }
    return -1;
  }

}
