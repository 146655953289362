<div class="row mt-3">
  <!--<img class="inner" src="assets/images/team-maps/tm-flex.svg" [height]="800" [width]="800" />-->
  <div class="col-md-7 pe-4 position-relative">
    <canvas baseChart
            class="position-absolute inner chart"
            [options]="scatterChartOptions"
            [data]="scatterChartData"
            [labels]="scatterChartLabels"
            [plugins]="plugins"
            [type]="scatterChartType"></canvas>
    <svg *ngIf="!isFlex" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="a" x="0" y="0" version="1.1" viewBox="0 0 400 400">
    <style id="style2" type="text/css">
      .st0 {
        enable-background: new
      }

      .st1 {
        fill: #b0b1b0
      }

      .st2 {
        fill: none;
        stroke: #dadbda
      }

      .st3 {
        fill: #fff
      }
</style>
    <g id="g124" transform="matrix(1.01502 0 0 1.01554 -5.5 -5.7)">
        <!--Risk Tolerant-->
    <g id="g28" class="st0" [class.highlight]="letterHighlight === LetterHighlight.Right">
    <path id="path4" d="M395.4 174.9v2.9c0 .6-.2 1.1-.5 1.5-.3.4-.8.6-1.5.6-.5 0-.9-.1-1.2-.4l-.7-1h-.1l-2.7 1.5h-.1v-1.1l2.8-1.6v-1.5h-2.8v-1h6.8zm-3 2.8c0 .3.1.6.3.8.2.2.5.3.8.3.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8v-1.9h-2.1v1.9z" class="st1" />
    <path id="path6" d="M393.5 181.2v1h-4.8v-1zm2.1-.1v1h-1v-1z" class="st1" />
    <path id="path8" d="M389.1 184c.3-.4.7-.6 1.2-.7v.9c-.3.1-.5.2-.6.4-.2.2-.2.5-.2.9l.2.8c.1.2.3.3.5.3l.4-.2.2-.5.1-.9c.1-.5.2-.8.4-1 .2-.2.5-.4.9-.4s.8.2 1.1.5c.3.3.4.8.4 1.4 0 .6-.1 1-.4 1.4-.3.3-.7.5-1.1.6v-.9c.5-.1.7-.5.7-1.1l-.2-.7c-.1-.2-.3-.2-.5-.2-.3 0-.5.2-.5.6l-.2 1.1c-.1.4-.2.7-.4.9-.2.2-.5.3-.9.3-.5 0-.8-.2-1.1-.5-.3-.3-.4-.8-.4-1.5-.1-.7.1-1.2.4-1.5z" class="st1" />
    <path id="path10" d="M395.6 188.6v1h-3.9v.1l1.9 2v1.1h-.1l-1.8-1.8h-.1l-2.7 2h-.1v-1.1l2.2-1.6-.5-.5h-1.7v-1h6.8z" class="st1" />
    <path id="path12" d="M394.5 197.6v-2.1h.9v5.2h-.9v-2.1h-5.8v-1z" class="st1" />
    <path id="path14" d="M389 201.6c.2-.4.5-.6.9-.9.4-.2.8-.3 1.3-.3s.9.1 1.3.3c.4.2.7.5.9.9l.3 1.2c0 .5-.1.9-.3 1.2-.2.4-.5.6-.9.9-.4.3-.8.3-1.3.3s-.9-.1-1.3-.3a2 2 0 0 1-.9-.9l-.3-1.2c-.1-.4.1-.9.3-1.2zm.9.2c-.3.3-.4.6-.4 1s.1.8.4 1c.3.3.7.4 1.2.4s.9-.1 1.2-.4c.3-.3.4-.6.4-1s-.1-.8-.4-1c-.3-.2-.7-.4-1.2-.4s-.9.1-1.2.4z" class="st1" />
    <path id="path16" d="M395.6 206.4v1h-6.8v-1z" class="st1" />
    <path id="path18" d="M389 209.7c.2-.4.5-.7.9-.9.4-.2.8-.3 1.3-.3s.9.1 1.3.3c.4.2.7.5.9.8.2.3.3.8.3 1.2l-.3 1.2a3 3 0 0 1-.8.8l-1.2.3h-.4v-3.5c-.4.1-.8.2-1 .5-.2.3-.4.6-.4 1l.2.8c.1.2.3.3.6.4v.9c-.5-.1-.8-.3-1.1-.7-.3-.4-.4-.9-.4-1.4-.3-.6-.1-1.1.1-1.4zm2.6 2.4c.4 0 .7-.2.9-.4.2-.2.3-.5.3-.9l-.3-.9c-.2-.2-.5-.4-.9-.4z" class="st1" />
    <path id="path20" d="M393.5 214.2v.9l-.7.1v.1l.6.6.2.9v.2h-.9v-.4c0-.4-.1-.8-.4-1-.2-.2-.6-.4-1-.4h-2.6v-1z" class="st1" />
    <path id="path22" d="M389.1 217.9c.3-.3.6-.5 1.1-.5.5 0 .8.2 1.1.5.3.3.4.8.4 1.4v1.3h.2c.3 0 .6-.1.7-.3.2-.2.2-.5.2-.8 0-.6-.3-1-.9-1.1v-.9c.5 0 .9.2 1.2.6.3.4.5.8.5 1.4 0 .6-.1 1.1-.4 1.5-.3.4-.7.5-1.3.5h-2l-.3.1-.1.3v.1h-.9v-.3l.2-.7.5-.4v-.1c-.5-.3-.8-.8-.8-1.5.1-.4.3-.8.6-1.1zm.6.7-.2.7c0 .4.1.7.3 1 .2.2.5.4.9.4h.1v-1.2c0-.3 0-.6-.2-.8a.5.5 0 0 0-.5-.3c-.1-.1-.3 0-.4.2z" class="st1" />
    <path id="path24" d="M393.5 223.1v.9l-.6.1v.1c.4.3.7.8.7 1.4 0 .5-.2 1-.5 1.3a2 2 0 0 1-1.4.5h-3v-1h2.8c.4 0 .6-.1.9-.3.2-.2.3-.5.3-.8 0-.4-.1-.7-.4-.9-.3-.2-.6-.3-1-.3h-2.7v-1z" class="st1" />
    <path id="path26" d="M389 229.4c.2-.2.5-.3.9-.3h2.8V228h.8v.5l.2.6.8.1h.5v.9h-1.5v1.3h-.8v-1.3h-2.6l-.4.1-.1.5v.7h-.8v-1c-.1-.5 0-.8.2-1z" class="st1" />
        </g>
    <path id="Line_847" d="M21.8 202.9h360" class="st2" />
    <path id="Line_848" d="M201.8 23.9v358" class="st2" />
        <!--Composed-->
    <g id="g48" class="st0" [class.highlight]="letterHighlight === LetterHighlight.Top">
    <path id="path32" d="M179.7 16c-.5-.3-.9-.7-1.2-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.7-.9 1.2-1.2.5-.3 1.1-.4 1.7-.4.5 0 1 .1 1.4.3.4.2.8.5 1 .8.3.4.5.8.5 1.2h-1.1c-.1-.4-.3-.8-.7-1-.4-.2-.8-.4-1.2-.4l-1.2.3c-.3.2-.6.5-.8.9-.2.4-.3.8-.3 1.3s.1.9.3 1.3c.2.4.4.7.8.9.3.2.7.3 1.2.3a2 2 0 0 0 1.3-.4c.3-.3.6-.6.7-1h1.1l-.5 1.2c-.3.4-.6.6-1 .8-.4.2-.9.3-1.4.3-.8.1-1.3-.1-1.8-.4z" class="st1" />
    <path id="path34" d="M186.4 16.2c-.4-.2-.6-.5-.9-.9-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.9-.9l1.2-.3c.4 0 .9.1 1.2.3.4.2.6.5.9.9.2.4.3.8.3 1.3s-.1.9-.3 1.3a2 2 0 0 1-.9.9l-1.2.3c-.4 0-.9-.1-1.2-.3zm.2-1c.3.3.6.4 1 .4s.8-.1 1-.4c.3-.3.4-.7.4-1.2s-.1-.9-.4-1.2c-.3-.3-.6-.4-1-.4s-.8.1-1 .4c-.3.3-.4.7-.4 1.2s.1.9.4 1.2z" class="st1" />
    <path id="path36" d="M191.1 11.6h.9l.1.6h.1c.3-.4.8-.7 1.3-.7l.8.2c.2.1.4.3.5.6h.1a1.9 1.9 0 0 1 1.5-.8c.5 0 .9.2 1.2.5.3.3.4.7.4 1.2v3.2h-1v-3a1 1 0 0 0-.3-.7 1 1 0 0 0-.7-.3c-.3 0-.6.1-.8.3-.2.2-.3.5-.3.8v2.9h-1v-3a1 1 0 0 0-.3-.7 1 1 0 0 0-.7-.3c-.3 0-.6.1-.8.3-.2.2-.3.5-.3.8v2.9h-1v-4.8z" class="st1" />
    <path id="path38" d="M199.4 11.6h.8l.1.6h.1c.3-.5.8-.7 1.5-.7.4 0 .8.1 1.1.3.3.2.6.5.8.9.2.4.3.8.3 1.3s-.1.9-.3 1.3c-.2.4-.4.7-.8.9-.4.2-.7.3-1.1.3-.6 0-1-.2-1.4-.6h-.1v2.4h-1zm1.3 3.6c.2.3.6.4 1 .4s.7-.1 1-.4c.3-.3.4-.7.4-1.2s-.1-.9-.4-1.2c-.3-.3-.6-.4-1-.4s-.8.1-1 .4c-.2.3-.4.7-.4 1.2l.4 1.2z" class="st1" />
    <path id="path40" d="M206.1 16.2c-.4-.2-.6-.5-.9-.9-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.9-.9l1.2-.3c.5 0 .9.1 1.2.3.4.2.6.5.9.9.2.4.3.8.3 1.3s-.1.9-.3 1.3a2 2 0 0 1-.9.9l-1.2.3c-.4 0-.9-.1-1.2-.3zm.2-1c.3.3.6.4 1 .4s.8-.1 1-.4c.3-.3.4-.7.4-1.2s-.1-.9-.4-1.2c-.3-.3-.6-.4-1-.4s-.8.1-1 .4c-.3.3-.4.7-.4 1.2s.1.9.4 1.2z" class="st1" />
    <path id="path42" d="M211.2 16.1c-.4-.3-.6-.7-.7-1.2h.9c.1.3.2.5.4.6.2.2.5.2.9.2l.8-.2c.2-.1.3-.3.3-.5l-.2-.4-.5-.2-1-.1c-.5-.1-.8-.2-1-.4-.2-.2-.3-.5-.3-.9s.2-.8.5-1.1c.3-.3.8-.4 1.4-.4.6 0 1 .1 1.4.4.3.3.5.7.6 1.1h-.9c-.1-.5-.5-.7-1.1-.7l-.7.2c-.2.1-.2.3-.2.5 0 .3.2.5.6.5l1.1.2c.4.1.7.2.9.4.2.2.3.5.3.9 0 .5-.2.8-.5 1.1-.3.3-.8.4-1.5.4-.6 0-1.1-.2-1.5-.4z" class="st1" />
    <path id="path44" d="M216.7 16.2a2 2 0 0 1-.9-.9c-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.8-.9l1.2-.3 1.2.3.8.8c.2.3.3.8.3 1.2v.4h-3.5c.1.4.2.8.5 1 .3.2.6.4 1 .4l.8-.2c.2-.1.3-.3.4-.6h.9c-.1.5-.3.8-.7 1.1-.4.3-.9.4-1.4.4-.6.2-1 .1-1.4-.1zm2.5-2.7c0-.4-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-.9.3c-.2.2-.4.5-.4.9z" class="st1" />
    <path id="path46" d="M222 16.2c-.3-.2-.6-.5-.8-.9-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.4-.7.8-.9.3-.2.7-.3 1.2-.3.6 0 1 .2 1.4.6h.1V9.6h1v6.8h-.8l-.1-.6h-.1c-.3.5-.8.7-1.5.7-.5 0-.8-.1-1.2-.3zm.4-1c.2.3.6.4 1 .4s.8-.1 1-.4c.2-.3.4-.7.4-1.2s-.1-.9-.4-1.2c-.2-.3-.6-.4-1-.4s-.7.1-1 .4c-.2.3-.4.7-.4 1.2s.1.9.4 1.2z" class="st1" />
        </g>
        <!--Risk Aware-->
    <g id="g68" class="st0" [class.highlight]="letterHighlight === LetterHighlight.Bottom">
    <path id="path50" d="M177.8 388.6h2.9c.6 0 1.1.2 1.5.5.4.3.6.8.6 1.5 0 .5-.1.9-.4 1.2l-1 .7v.1l1.5 2.7v.1h-1.1l-1.6-2.8h-1.5v2.8h-1v-6.8zm2.9 3.1c.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8 0-.3-.1-.6-.3-.8-.2-.2-.5-.3-.8-.3h-1.9v2.1h1.9z" class="st1" />
    <path id="path52" d="M184.1 388.5h1v1h-1zm0 2h1v4.8h-1z" class="st1" />
    <path id="path54" d="M186.9 395c-.4-.3-.6-.7-.7-1.2h.9c.1.3.2.5.4.6.2.2.5.2.9.2l.8-.2c.2-.1.3-.3.3-.5l-.2-.4-.5-.2-.9-.1c-.5-.1-.8-.2-1-.4-.2-.2-.4-.5-.4-.9s.2-.8.5-1.1c.3-.3.8-.4 1.4-.4.6 0 1 .1 1.4.4.3.3.5.7.6 1.1h-.9c-.1-.5-.5-.7-1.1-.7l-.7.2c-.2.1-.2.3-.2.5 0 .3.2.5.6.5l1.1.2c.4.1.7.2.9.4.2.2.3.5.3.9 0 .5-.2.8-.5 1.1-.3.3-.8.4-1.5.4-.6 0-1.1-.1-1.5-.4z" class="st1" />
    <path id="path56" d="M191.6 388.5h1v3.9h.1l2-1.9h1.1v.1l-1.8 1.8v.1l2 2.7v.1h-1.1l-1.6-2.2-.5.5v1.7h-1v-6.8z" class="st1" />
    <path id="path58" d="m198.4 395.2 2.5-6.6h1.3l2.5 6.6v.1h-1l-.6-1.6H200l-.6 1.6zm4.3-2.4-1.2-3.1h-.1l-1.2 3.1z" class="st1" />
    <path id="path60" d="M204.8 390.6v-.1h1l.9 3.8h.1l1-3.8h1.3l1 3.8h.1l.9-3.8h.9v.1l-1.2 4.7h-1.3l-1-3.8h-.1l-1 3.8h-1.3z" class="st1" />
    <path id="path62" d="M213.1 395c-.3-.3-.5-.6-.5-1.1 0-.5.2-.8.5-1.1.3-.3.8-.4 1.4-.4h1.3v-.2c0-.3-.1-.6-.3-.7-.2-.2-.5-.2-.8-.2-.6 0-1 .3-1.1.9h-.9c0-.5.2-.9.6-1.2.4-.3.8-.5 1.4-.5.6 0 1.1.1 1.5.4.4.3.5.7.5 1.3v2l.1.3.3.1h.1v.9h-.3l-.7-.2-.4-.5h-.1c-.3.5-.8.8-1.5.8-.4-.2-.8-.3-1.1-.6zm.7-.6.7.2c.4 0 .7-.1 1-.3.2-.2.4-.5.4-.9v-.1h-1.2c-.3 0-.6 0-.8.2-.2.1-.3.3-.3.5l.2.4z" class="st1" />
    <path id="path64" d="M218.4 390.5h.9l.1.7h.1l.6-.6.9-.2h.2v.9h-.4c-.4 0-.8.1-1 .4-.2.2-.4.6-.4 1v2.6h-1z" class="st1" />
    <path id="path66" d="M222.7 395.1a2 2 0 0 1-.9-.9c-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.8-.9l1.2-.3 1.2.3.8.8c.2.3.3.8.3 1.2v.4h-3.5c.1.4.2.8.5 1 .3.2.6.4 1 .4l.8-.2c.2-.1.3-.3.4-.6h.9c-.1.5-.3.8-.7 1.1-.4.3-.9.4-1.4.4-.6.2-1 .1-1.4-.1zm2.5-2.6c0-.4-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-.9.3c-.2.2-.4.5-.4.9z" class="st1" />
        </g>
        <!--Measured-->
    <g id="g86" class="st0" [class.highlight]="letterHighlight === LetterHighlight.Left">
    <path id="path70" d="M9.6 224.4v-1.7l5.5-1.7v-.1l-5.5-1.7v-1.7h6.7v1h-5.6v.1l5.6 1.7v1.3l-5.6 1.7v.1h5.6v1z" class="st1" />
    <path id="path72" d="M16 215a2 2 0 0 1-.9.9c-.4.2-.8.3-1.3.3s-.9-.1-1.3-.3c-.4-.2-.7-.5-.9-.8l-.3-1.2.3-1.2.8-.8 1.2-.3h.4v3.5c.4-.1.8-.2 1-.5.2-.3.4-.6.4-1l-.2-.8c-.1-.2-.3-.3-.6-.4v-.9c.5.1.8.3 1.1.7.3.4.4.9.4 1.4.2.7.1 1.1-.1 1.4zm-2.6-2.4c-.4 0-.7.2-.9.4-.2.2-.3.5-.3.9l.3.9c.2.2.5.4.9.4z" class="st1" />
    <path id="path74" d="M15.9 210.3c-.3.3-.6.5-1.1.5-.5 0-.8-.2-1.1-.5-.3-.3-.4-.8-.4-1.4v-1.3h-.2c-.3 0-.6.1-.7.3-.2.2-.2.5-.2.8 0 .6.3 1 .9 1.1v.9c-.5 0-.9-.2-1.2-.6-.3-.4-.5-.8-.5-1.4 0-.6.1-1.1.4-1.5.3-.4.7-.5 1.3-.5h2l.3-.1.1-.3v-.1h.9v.3l-.2.7-.5.4v.1c.5.3.8.8.8 1.5-.2.4-.3.8-.6 1.1zm-.6-.7.2-.7c0-.4-.1-.7-.3-1-.2-.3-.5-.4-.9-.4h-.1v1.2l.2.8c.1.2.3.3.5.3l.4-.2z" class="st1" />
    <path id="path76" d="M15.9 204.7c-.3.4-.7.6-1.2.7v-.9c.3-.1.5-.2.6-.4.2-.2.2-.5.2-.9l-.2-.8a.5.5 0 0 0-.5-.3l-.4.2-.2.5-.1.9c-.1.5-.2.8-.4 1-.2.2-.5.4-.9.4s-.8-.2-1.1-.5c-.3-.3-.4-.8-.4-1.4 0-.6.1-1 .4-1.4.3-.3.7-.5 1.1-.6v.9c-.5.1-.7.5-.7 1.1l.2.7c.1.2.3.2.5.2.3 0 .5-.2.5-.6l.2-1.1c.1-.4.2-.7.4-.9.2-.2.5-.3.9-.3.5 0 .8.2 1.1.5.3.3.4.8.4 1.5 0 .6-.1 1.1-.4 1.5z" class="st1" />
    <path id="path78" d="M15.8 199.7a2 2 0 0 1-1.4.5h-3v-1h2.8c.4 0 .6-.1.9-.3.3-.2.3-.4.3-.8s-.1-.6-.4-.9c-.3-.3-.6-.3-1-.3h-2.7v-1h4.8v.9l-.6.1v.1c.4.3.7.8.7 1.4.1.5 0 1-.4 1.3z" class="st1" />
    <path id="path80" d="M11.5 194.5v-.9l.7-.1v-.1l-.6-.6a2 2 0 0 1-.2-.9v-.2h.9v.4c0 .4.1.8.4 1 .2.2.6.4 1 .4h2.6v1z" class="st1" />
    <path id="path82" d="M16 190.1a2 2 0 0 1-.9.9c-.4.2-.8.3-1.3.3s-.9-.1-1.3-.3c-.4-.2-.7-.5-.9-.8l-.3-1.2.3-1.2.8-.8 1.2-.3h.4v3.5c.4-.1.8-.2 1-.5.2-.3.4-.6.4-1l-.2-.8c-.1-.2-.3-.3-.6-.4v-.9c.5.1.8.3 1.1.7.3.4.4.9.4 1.4.2.7.1 1.1-.1 1.4zm-2.6-2.4c-.4 0-.7.2-.9.4-.2.2-.3.5-.3.9l.3.9c.2.2.5.4.9.4z" class="st1" />
    <path id="path84" d="M16 184.8c-.2.3-.5.6-.9.8-.4.2-.8.3-1.3.3s-.9-.1-1.3-.3c-.4-.2-.7-.4-.9-.8-.2-.3-.3-.7-.3-1.2 0-.6.2-1 .6-1.4v-.1H9.4v-1h6.8v.8l-.6.1v.1c.5.3.7.8.7 1.5 0 .5-.1.9-.3 1.2zm-.9-.3c.3-.2.4-.6.4-1s-.1-.8-.4-1c-.3-.2-.7-.4-1.2-.4s-.9.1-1.2.4c-.3.2-.4.6-.4 1s.1.7.4 1c.3.2.7.4 1.2.4s.9-.1 1.2-.4z" class="st1" />
        </g>
    <g id="g90" class="st0">
    <path id="path88" d="m312.4 259.9-3.9 10.6h-9.8l-5.9 16.4h8.6l-3.9 10.6h-8.6l-6.2 17.2h9.8l-3.8 10.6h-22.1l23.8-65.5h22z" class="st3" />
        </g>
    <g id="g94" class="st0">
    <path id="path92" fill="none" stroke="#868687" stroke-width="1.3" d="m312.4 259.9-3.9 10.6h-9.8l-5.9 16.4h8.6l-3.9 10.6h-8.6l-6.2 17.2h9.8l-3.8 10.6h-22.1l23.8-65.5h22z" />
        </g>
    <g id="g98" class="st0">
    <path id="path96" d="M123.7 259.9c10.1 0 16.2 9.5 11.1 23.3l-.6 1.6c-4.5 12.1-12.8 17.7-24.7 17.7l-8.3 22.9H88.9l23.8-65.5zm-10.6 32.7h.7c3.8 0 6.1-1.6 7.4-5.5l3.5-9.7c1.6-4.6.6-7.8-2.7-7.8h-.6z" class="st3" />
        </g>
    <g id="g102" class="st0">
    <path id="path100" fill="none" stroke="#7000dd" stroke-width="1.3" d="M123.7 259.9c10.1 0 16.2 9.5 11.1 23.3l-.6 1.6c-4.5 12.1-12.8 17.7-24.7 17.7l-8.3 22.9H88.9l23.8-65.5zm-10.6 32.7h.7c3.8 0 6.1-1.6 7.4-5.5l3.5-9.7c1.6-4.6.6-7.8-2.7-7.8h-.6z" />
        </g>
    <g id="g112">
    <g id="g106" class="st0">
    <path id="path104" d="M122.6 80.1c10 0 13.5 8.8 9 21.3-.8 2.3-1.1 4-1.1 5.3H118c0-1.1.2-2.1.7-3.7l3.4-9.3c.7-2 .2-3.8-2-3.8-2 0-3.4 1.5-4.4 4.2l-14 38.5c-1 2.7 0 4.4 2.1 4.4 1.8 0 2.9-1.3 3.7-3.3l2.7-7.4a9.2 9.2 0 0 1 2-3.7h11.9v.8c-1.1 1.2-1.6 3-2.2 4.5-4.7 12.8-11.4 19.2-20.2 19.2-8.6 0-15.8-8.7-7-32.3l2.1-5.7c6.7-18 15.2-29 25.8-29z" class="st3" />
          </g>
    <g id="g110" class="st0">
    <path id="path108" fill="none" stroke="#000" stroke-width="1.3" d="M122.6 80.1c10 0 13.5 8.8 9 21.3-.8 2.3-1.1 4-1.1 5.3H118c0-1.1.2-2.1.7-3.7l3.4-9.3c.7-2 .2-3.8-2-3.8-2 0-3.4 1.5-4.4 4.2l-14 38.5c-1 2.7 0 4.4 2.1 4.4 1.8 0 2.9-1.3 3.7-3.3l2.7-7.4a9.2 9.2 0 0 1 2-3.7h11.9v.8c-1.1 1.2-1.6 3-2.2 4.5-4.7 12.8-11.4 19.2-20.2 19.2-8.6 0-15.8-8.7-7-32.3l2.1-5.7c6.7-18 15.2-29 25.8-29z" />
          </g>
        </g>
    <g id="g122">
    <g id="g116" class="st0">
    <path id="path114" d="M274.2 109.2c6.7-18.1 15.1-29 25.4-29 8.6 0 15.7 9 7 32.3l-2.1 5.7c-6.7 18.1-15.1 29-25.4 29-8.6 0-15.8-8.7-7-32.3zm4.9 23.4c-1 2.7 0 4.4 2.1 4.4 2 0 3.4-1.5 4.3-4.2l14-38.5c1-2.7 0-4.4-2.1-4.4-2 0-3.4 1.5-4.3 4.2z" class="st3" />
          </g>
    <g id="g120" class="st0">
    <path id="path118" fill="none" stroke="#fd5f00" stroke-width="1.3" d="M274.2 109.2c6.7-18.1 15.1-29 25.4-29 8.6 0 15.7 9 7 32.3l-2.1 5.7c-6.7 18.1-15.1 29-25.4 29-8.6 0-15.8-8.7-7-32.3zm4.9 23.4c-1 2.7 0 4.4 2.1 4.4 2 0 3.4-1.5 4.3-4.2l14-38.5c1-2.7 0-4.4-2.1-4.4-2 0-3.4 1.5-4.3 4.2z" />
          </g>
        </g>
      </g>
    </svg>
    <svg *ngIf="isFlex" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="a" x="0" y="0" version="1.1" viewBox="0 0 400 400">
    <style id="style2" type="text/css">
      .st0 {
        enable-background: new
      }

      .st1 {
        fill: #b0b1b0
      }

      .st2 {
        fill: none;
        stroke: #dadbda
      }

      .st3 {
        fill: #fff
      }
</style>
    <g id="g178" transform="matrix(1.01528 0 0 1.0111 -2.7 -2.7)">
        <!--Internally Focused-->
    <g id="g38" class="st0" [class.highlight]="letterHighlight === LetterHighlight.Right">
    <path id="path4" d="M392.6 159.5v1h-6.7v-1z" class="st1" />
    <path id="path6" d="M390.7 162.1v.9l-.6.1v.1c.4.3.7.8.7 1.4 0 .5-.2 1-.5 1.3a2 2 0 0 1-1.4.5h-3v-1h2.8c.4 0 .6-.1.9-.3.2-.2.3-.5.3-.8 0-.4-.1-.7-.4-.9-.3-.2-.6-.3-1-.3h-2.7v-1z" class="st1" />
    <path id="path8" d="M386.2 168.4c.2-.2.5-.3.9-.3h2.8V167h.8v.5l.2.6.8.1h.5v.9h-1.5v1.3h-.8v-1.3h-2.6l-.4.1-.1.5v.7h-.8v-1c-.1-.5 0-.8.2-1z" class="st1" />
    <path id="path10" d="M386.1 172.3c.2-.4.5-.7.9-.9.4-.2.8-.3 1.3-.3s.9.1 1.3.3c.4.2.7.5.9.8l.3 1.2-.3 1.2a3 3 0 0 1-.8.8c-.3.2-.8.3-1.2.3h-.4v-3.5c-.4.1-.8.2-1 .5-.2.3-.4.6-.4 1l.2.8c.1.2.3.3.6.4v.9c-.5-.1-.8-.3-1.1-.7-.3-.4-.4-.9-.4-1.4-.2-.7-.1-1.1.1-1.4zm2.6 2.4c.4 0 .7-.2.9-.4.2-.2.3-.5.3-.9l-.3-.9c-.2-.2-.5-.4-.9-.4z" class="st1" />
    <path id="path12" d="M390.7 176.8v.9l-.7.1v.1l.6.6.2.9v.2h-.9v-.4c0-.4-.1-.8-.4-1-.2-.2-.6-.4-1-.4h-2.6v-1z" class="st1" />
    <path id="path14" d="M390.7 180.4v.9l-.6.1v.1c.4.3.7.8.7 1.4 0 .5-.2 1-.5 1.3a2 2 0 0 1-1.4.5h-3v-1h2.8c.4 0 .6-.1.9-.3.2-.2.3-.5.3-.8 0-.4-.1-.7-.4-.9-.3-.2-.6-.3-1-.3h-2.7v-1z" class="st1" />
    <path id="path16" d="M386.2 186.2c.3-.3.6-.5 1.1-.5.5 0 .8.2 1.1.5.3.3.4.8.4 1.4v1.3h.2c.3 0 .6-.1.7-.3.2-.2.2-.4.2-.8 0-.6-.3-1-.9-1.1v-.9c.5 0 .9.2 1.2.6.3.3.5.8.5 1.4 0 .6-.1 1.1-.4 1.5-.3.4-.7.5-1.3.5h-2l-.3.1-.1.3v.1h-.9v-.3l.2-.7.5-.4v.1c-.5-.3-.8-.8-.8-1.5.2-.5.3-.9.6-1.3zm.6.8-.2.7c0 .4.1.7.3 1 .2.2.5.4.9.4h.1v-1.2c0-.3 0-.6-.2-.8a.5.5 0 0 0-.5-.3c-.1-.1-.3 0-.4.2z" class="st1" />
    <path id="path18" d="M392.7 191.5v1h-6.8v-1z" class="st1" />
    <path id="path20" d="M392.7 194v1h-6.8v-1z" class="st1" />
    <path id="path22" d="M384.8 196.4v.5l.1.5.4.3.2.1h.1l5-1.9h.1v1l-3.8 1.4v.1l3.8 1.3v1h-.1l-5.6-2.1c-.4-.1-.6-.3-.8-.6l-.2-1.1v-.5z" class="st1" />
    <path id="path24" d="M392.6 203.9v4.5h-.9v-3.5h-2v3h-.9v-3H386v-1z" class="st1" />
    <path id="path26" d="M386.1 210.1c.2-.4.5-.6.9-.9.4-.2.8-.3 1.3-.3s.9.1 1.3.3c.4.2.7.5.9.9l.3 1.2c0 .5-.1.9-.3 1.2-.2.4-.5.6-.9.9-.4.2-.8.3-1.3.3s-.9-.1-1.3-.3a2 2 0 0 1-.9-.9l-.3-1.2.3-1.2zm1 .3c-.3.3-.4.6-.4 1s.1.8.4 1c.3.3.7.4 1.2.4s.9-.1 1.2-.4c.3-.3.4-.6.4-1s-.1-.8-.4-1c-.3-.3-.7-.4-1.2-.4s-.9.1-1.2.4z" class="st1" />
    <path id="path28" d="M386.1 215.7c.2-.4.5-.6.9-.8.4-.2.8-.3 1.3-.3s.9.1 1.3.3c.4.2.7.5.9.8l.3 1.2c0 .6-.2 1.1-.5 1.5-.3.4-.8.6-1.4.7v-.9c.7-.2 1-.6 1-1.2 0-.4-.1-.8-.4-1-.3-.2-.7-.4-1.2-.4s-.9.1-1.2.4c-.3.2-.4.6-.4 1 0 .7.3 1.1 1 1.3v.9c-.6-.1-1.1-.3-1.4-.7-.3-.4-.5-.9-.5-1.5 0-.5.1-.9.3-1.3z" class="st1" />
    <path id="path30" d="M386.3 220.6a2 2 0 0 1 1.4-.5h3v1h-2.8c-.4 0-.6.1-.9.3-.3.2-.3.4-.3.8s.1.6.4.9c.2.2.6.3 1 .3h2.7v1H386v-.9l.6-.1v-.1c-.4-.3-.7-.8-.7-1.4-.1-.5 0-1 .4-1.3z" class="st1" />
    <path id="path32" d="M386.2 226.1c.3-.4.7-.6 1.2-.7v.9c-.3.1-.5.2-.6.4-.2.2-.2.5-.2.9l.2.8c.1.2.3.3.5.3l.4-.2.2-.5.1-1c.1-.5.2-.8.4-1 .2-.2.5-.3.9-.3s.8.2 1.1.5c.3.3.4.8.4 1.4 0 .6-.1 1-.4 1.4-.3.3-.7.5-1.1.6v-.9c.5-.1.7-.5.7-1.1l-.2-.7c-.1-.2-.3-.2-.5-.2-.3 0-.5.2-.5.6l-.2 1.1c-.1.4-.2.7-.4.9-.2.2-.5.3-.9.3-.5 0-.8-.2-1.1-.5-.3-.3-.4-.8-.4-1.5 0-.6.1-1.1.4-1.5z" class="st1" />
    <path id="path34" d="M386.1 231.6c.2-.4.5-.7.9-.9.4-.2.8-.3 1.3-.3s.9.1 1.3.3c.4.2.7.5.9.8l.3 1.2-.3 1.2a3 3 0 0 1-.8.8c-.3.2-.8.3-1.2.3h-.4v-3.5c-.4.1-.8.2-1 .5-.2.3-.4.6-.4 1l.2.8c.1.2.3.3.6.4v.9c-.5-.1-.8-.3-1.1-.7-.3-.4-.4-.9-.4-1.4-.2-.6-.1-1 .1-1.4zm2.6 2.5c.4 0 .7-.2.9-.4.2-.2.3-.5.3-.9l-.3-.9c-.2-.2-.5-.4-.9-.4z" class="st1" />
    <path id="path36" d="M386.1 237c.2-.3.5-.6.9-.8.4-.2.8-.3 1.3-.3s.9.1 1.3.3c.4.2.7.4.9.8.2.3.3.7.3 1.2 0 .6-.2 1-.6 1.4v.1h2.5v1h-6.8v-.8l.6-.1v-.1c-.5-.3-.7-.8-.7-1.5 0-.5.1-.9.3-1.2zm1 .3c-.3.2-.4.6-.4 1s.1.8.4 1c.3.2.7.4 1.2.4s.9-.1 1.2-.4c.3-.2.4-.6.4-1s-.1-.7-.4-1c-.3-.2-.7-.4-1.2-.4s-.9.1-1.2.4z" class="st1" />
        </g>
    <path id="Line_847" d="M18.9 199.9h360" class="st2" />
    <path id="Line_848" d="M198.9 20.9v358" class="st2" />
        <!--Task-Oriented-->
    <g id="g68" class="st0" [class.highlight]="letterHighlight === LetterHighlight.Top">
    <path id="path42" d="M169.6 7.7h-2.1v-1h5.2v.9h-2.1v5.8h-1z" class="st1" />
    <path id="path44" d="M172.9 13.1c-.3-.3-.5-.6-.5-1.1 0-.5.2-.8.5-1.1.3-.3.8-.4 1.4-.4h1.3v-.2c0-.3-.1-.6-.3-.7-.2-.2-.5-.2-.8-.2-.6 0-1 .3-1.1.9h-.9c0-.5.2-.9.6-1.2a2 2 0 0 1 1.4-.5c.6 0 1.1.1 1.5.4.3.3.5.7.5 1.3v2l.1.3.3.1h.1v.9h-.3l-.7-.2-.4-.5h-.1c-.3.5-.8.8-1.5.8-.4-.2-.8-.3-1.1-.6zm.7-.6.7.2c.4 0 .7-.1 1-.3.2-.2.4-.5.4-.9v-.1h-1.2c-.3 0-.6 0-.8.2-.2.1-.3.3-.3.5l.2.4z" class="st1" />
    <path id="path46" d="M178.5 13.1c-.4-.3-.6-.7-.7-1.2h.9c.1.3.2.5.4.6.2.2.5.2.9.2l.8-.2c.2-.1.3-.3.3-.5l-.2-.4-.5-.2-1-.1c-.5-.1-.8-.2-1-.4-.2-.2-.3-.5-.3-.9s.2-.8.5-1.1c.3-.3.8-.4 1.4-.4.6 0 1 .1 1.4.4.3.3.5.7.6 1.1h-1c-.1-.5-.5-.7-1.1-.7l-.7.2c-.2.1-.2.3-.2.5 0 .3.2.5.6.5l1.1.2c.4.1.7.2.9.4.2.2.3.5.3.9 0 .5-.2.8-.5 1.1-.3.3-.8.4-1.5.4-.5 0-1-.1-1.4-.4z" class="st1" />
    <path id="path48" d="M183.2 6.6h1v3.9h.1l2-1.9h1.1v.1l-1.8 1.8v.1l2 2.7v.1h-1.1l-1.6-2.2-.5.5v1.7h-1V6.6Z" class="st1" />
    <path id="path50" d="M187.8 10.3h3.1v.8h-3.1z" class="st1" />
    <path id="path52" d="M193.7 13.1c-.5-.3-.9-.7-1.2-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.7-.9 1.2-1.2a3 3 0 0 1 1.7-.4 3 3 0 0 1 1.7.4c.5.3.9.7 1.2 1.2.3.5.4 1.1.4 1.8s-.1 1.3-.4 1.8c-.3.5-.7.9-1.2 1.2a3 3 0 0 1-1.7.4 3 3 0 0 1-1.7-.4zm-.3-1.7c.2.4.4.7.8.9.3.2.7.3 1.2.3l1.2-.3c.3-.2.6-.5.8-.9.2-.4.3-.8.3-1.3s-.1-.9-.3-1.3c-.2-.4-.4-.7-.8-.9-.3-.2-.7-.3-1.2-.3l-1.2.3c-.3.2-.6.5-.8.9-.2.4-.3.8-.3 1.3s.1.9.3 1.3z" class="st1" />
    <path id="path54" d="M199.9 8.6h.9l.1.7h.1l.6-.6.9-.2h.2v.9h-.4c-.4 0-.8.1-1 .4-.2.2-.4.6-.4 1v2.6h-1z" class="st1" />
    <path id="path56" d="M203.5 6.6h1v1h-1zm0 2h1v4.8h-1z" class="st1" />
    <path id="path58" d="M206.8 13.2a2 2 0 0 1-.9-.9c-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.8-.9.3-.2.8-.3 1.2-.3l1.2.3c.4.2.6.5.8.8l.3 1.2v.4h-3.5c.1.4.2.8.5 1 .3.2.6.4 1 .4l.8-.2c.2-.1.3-.3.4-.6h.9c-.1.5-.3.8-.7 1.1-.4.3-.9.4-1.4.4-.6.2-1.1.1-1.4-.1zm2.4-2.6c0-.4-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-.9.3c-.2.2-.4.5-.4.9z" class="st1" />
    <path id="path60" d="M211.3 8.6h.9l.1.6h.1c.3-.4.8-.7 1.4-.7.5 0 1 .2 1.3.5.3.3.5.8.5 1.4v3h-1v-2.8c0-.4-.1-.6-.3-.9-.2-.3-.5-.3-.8-.3-.4 0-.7.1-.9.4-.2.3-.3.6-.3 1v2.7h-1z" class="st1" />
    <path id="path62" d="M217.6 13.1c-.2-.2-.3-.5-.3-.9V9.4h-1.1v-.8h.5l.6-.2.1-.8v-.4h.9v1.5h1.3v.8h-1.3V12l.1.4.5.1h.7v.8h-1c-.5.1-.8 0-1-.2z" class="st1" />
    <path id="path64" d="M221.5 13.2a2 2 0 0 1-.9-.9c-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.8-.9l1.2-.3 1.2.3.8.8c.2.3.3.8.3 1.2v.4h-3.5c.1.4.2.8.5 1 .3.2.6.4 1 .4l.8-.2c.2-.1.3-.3.4-.6h.9c-.1.5-.3.8-.7 1.1-.4.3-.9.4-1.4.4-.7.2-1.1.1-1.4-.1zm2.4-2.6c0-.4-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-.9.3a2 2 0 0 0-.5.9z" class="st1" />
    <path id="path66" d="M226.8 13.2c-.3-.2-.6-.5-.8-.9-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.4-.7.8-.9.3-.2.7-.3 1.2-.3.6 0 1 .2 1.4.6h.1V6.6h1v6.8h-.8l-.1-.6h-.1c-.3.5-.8.7-1.5.7-.5 0-.9-.1-1.2-.3zm.3-1c.2.3.6.4 1 .4s.8-.1 1-.4c.2-.3.4-.7.4-1.2s-.1-.9-.4-1.2c-.2-.3-.6-.4-1-.4s-.7.1-1 .4c-.2.3-.4.7-.4 1.2 0 .6.2 1 .4 1.2z" class="st1" />
        </g>
        <!--People-Oriented-->
    <g id="g100" class="st0" [class.highlight]="letterHighlight === LetterHighlight.Bottom">
    <path id="path70" d="M162.3 385.7h2.7c.7 0 1.3.2 1.7.5.4.3.6.9.6 1.6 0 .7-.2 1.2-.6 1.6-.4.4-1 .5-1.7.5h-1.7v2.5h-1zm2.6 3.3c.4 0 .8-.1 1-.3.2-.2.4-.5.4-.9s-.1-.7-.4-.9c-.3-.2-.6-.3-1-.3h-1.6v2.4z" class="st1" />
    <path id="path72" d="M169.2 392.1a2 2 0 0 1-.9-.9c-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.8-.9.3-.2.8-.3 1.2-.3l1.2.3.8.8.3 1.2v.4H169c.1.4.2.8.5 1 .3.2.6.4 1 .4l.8-.2c.2-.1.3-.3.4-.6h.9c-.1.5-.3.8-.7 1.1-.4.3-.9.4-1.4.4-.6.3-1 .1-1.3-.1zm2.4-2.6c0-.4-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-.9.3c-.2.2-.4.5-.4.9z" class="st1" />
    <path id="path74" d="M174.6 392.1c-.4-.2-.6-.5-.9-.9-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.9-.9l1.2-.3c.5 0 .9.1 1.2.3.4.2.6.5.9.9.3.4.3.8.3 1.3s-.1.9-.3 1.3a2 2 0 0 1-.9.9l-1.2.3c-.5.1-.9-.1-1.2-.3zm.2-.9c.3.3.6.4 1 .4s.8-.1 1-.4c.3-.3.4-.7.4-1.2s-.1-.9-.4-1.2c-.3-.3-.6-.4-1-.4s-.8.1-1 .4c-.2.3-.4.7-.4 1.2s.1.9.4 1.2z" class="st1" />
    <path id="path76" d="M179.3 387.6h.8l.1.6h.1c.3-.5.8-.7 1.5-.7.4 0 .8.1 1.1.3.3.2.6.5.8.9.2.4.3.8.3 1.3s-.1.9-.3 1.3c-.2.4-.4.7-.8.9-.4.2-.7.3-1.1.3-.6 0-1-.2-1.4-.6h-.1v2.4h-1zm1.4 3.6c.2.3.6.4 1 .4s.7-.1 1-.4c.3-.3.4-.7.4-1.2s-.1-.9-.4-1.2c-.3-.3-.6-.4-1-.4s-.8.1-1 .4c-.2.3-.4.7-.4 1.2s.1.9.4 1.2z" class="st1" />
    <path id="path78" d="M185.2 385.5h1v6.8h-1z" class="st1" />
    <path id="path80" d="M188.5 392.1a2 2 0 0 1-.9-.9c-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.8-.9l1.2-.3 1.2.3.8.8c.2.3.3.8.3 1.2v.4h-3.5c.1.4.2.8.5 1 .3.2.6.4 1 .4l.8-.2c.2-.1.3-.3.4-.6h.9c-.1.5-.3.8-.7 1.1-.4.3-.9.4-1.4.4-.6.3-1 .1-1.4-.1zm2.4-2.6c0-.4-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-.9.3c-.2.2-.4.5-.4.9z" class="st1" />
    <path id="path82" d="M193.1 389.3h3.1v.8h-3.1z" class="st1" />
    <path id="path84" d="M199 392c-.5-.3-.9-.7-1.2-1.2-.3-.5-.4-1.1-.4-1.8s.1-1.3.4-1.8c.3-.5.7-.9 1.2-1.2a3 3 0 0 1 1.7-.4 3 3 0 0 1 1.7.4c.5.3.9.7 1.2 1.2.3.5.4 1.1.4 1.8s-.1 1.3-.4 1.8c-.3.5-.7.9-1.2 1.2a3 3 0 0 1-1.7.4 3 3 0 0 1-1.7-.4zm-.3-1.7c.2.4.4.7.8.9.3.2.7.3 1.2.3s.8-.1 1.2-.3c.3-.2.6-.5.8-.9.2-.4.3-.8.3-1.3s-.1-.9-.3-1.3c-.2-.4-.4-.7-.8-.9-.3-.2-.7-.3-1.2-.3s-.8.1-1.2.3c-.3.2-.6.5-.8.9-.2.4-.3.8-.3 1.3s.1.9.3 1.3z" class="st1" />
    <path id="path86" d="M205.1 387.6h.9l.1.7h.1l.6-.6.9-.2h.2v.9h-.4c-.4 0-.8.1-1 .4-.2.2-.4.6-.4 1v2.6h-1z" class="st1" />
    <path id="path88" d="M208.7 385.5h1v1h-1zm0 2.1h1v4.8h-1z" class="st1" />
    <path id="path90" d="M212 392.1a2 2 0 0 1-.9-.9c-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.8-.9.3-.2.8-.3 1.2-.3l1.2.3.8.8.3 1.2v.4h-3.5c.1.4.2.8.5 1 .3.2.6.4 1 .4l.8-.2c.2-.1.3-.3.4-.6h.9c-.1.5-.3.8-.7 1.1-.4.3-.9.4-1.4.4-.6.3-1 .1-1.4-.1zm2.5-2.6c0-.4-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-.9.3c-.2.2-.4.5-.4.9z" class="st1" />
    <path id="path92" d="M216.6 387.6h.9l.1.6h.1c.3-.4.8-.7 1.4-.7.5 0 1 .2 1.3.5.3.3.5.8.5 1.4v3h-1v-2.8c0-.4-.1-.6-.3-.9-.2-.2-.5-.3-.8-.3-.4 0-.7.1-.9.4-.2.3-.3.6-.3 1v2.7h-1z" class="st1" />
    <path id="path94" d="M222.9 392.1c-.2-.2-.3-.5-.3-.9v-2.8h-1.1v-.8h.5l.6-.2.1-.8v-.5h.9v1.5h1.3v.8h-1.3v2.6l.1.4.5.1h.7v.8h-1c-.5.1-.8 0-1-.2z" class="st1" />
    <path id="path96" d="M226.7 392.1a2 2 0 0 1-.9-.9c-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.8-.9.3-.2.8-.3 1.2-.3l1.2.3.8.8.3 1.2v.4h-3.5c.1.4.2.8.5 1 .3.2.6.4 1 .4l.8-.2c.2-.1.3-.3.4-.6h.9c-.1.5-.3.8-.7 1.1-.4.3-.9.4-1.4.4-.6.3-1 .1-1.4-.1zm2.5-2.6c0-.4-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-.9.3a2 2 0 0 0-.5.9z" class="st1" />
    <path id="path98" d="M232 392.1c-.3-.2-.6-.5-.8-.9-.2-.4-.3-.8-.3-1.3s.1-.9.3-1.3c.2-.4.5-.7.8-.9.3-.2.7-.3 1.2-.3.6 0 1 .2 1.4.6h.1v-2.5h1v6.8h-.8l-.1-.6h-.1c-.3.5-.8.7-1.5.7-.5.1-.8-.1-1.2-.3zm.4-.9c.2.3.6.4 1 .4s.8-.1 1-.4c.2-.3.4-.7.4-1.2s-.1-.9-.4-1.2c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-.2.3-.4.7-.4 1.2s.1.9.4 1.2z" class="st1" />
        </g>
        <!--Externally Focused-->
    <g id="g136" class="st0" [class.highlight]="letterHighlight === LetterHighlight.Left">
    <path id="path102" d="M6.7 241.8v-4.6h.9v3.6h2v-3.1h.9v3.1h1.9v-3.6h.9v4.6z" class="st1" />
    <path id="path104" d="m13.3 236.4-2.3-1.6h-.1l-2.2 1.5h-.1v-1.1l1.6-1v-.1L8.6 233v-1h.1l2.1 1.5h.1l2.4-1.7h.1v1.1l-1.7 1.2v.1l1.7 1.2z" class="st1" />
    <path id="path106" d="M13.1 230c-.2.2-.5.3-.9.3H9.4v1.1h-.8v-.5l-.2-.6-.8-.1h-.5v-.9h1.5V228h.8v1.3H12l.4-.1.1-.5v-.7h.8v1.1c.1.4 0 .7-.2.9z" class="st1" />
    <path id="path108" d="M13.2 226.2a2 2 0 0 1-.9.9c-.4.2-.8.3-1.3.3s-.9-.1-1.3-.3c-.4-.2-.7-.5-.9-.8-.2-.3-.3-.8-.3-1.2l.3-1.2c.2-.4.5-.6.8-.8.3-.2.8-.3 1.2-.3h.4v3.5c.4-.1.8-.2 1-.5.2-.3.4-.6.4-1l-.2-.8c-.1-.2-.3-.3-.6-.4v-.9c.5.1.8.3 1.1.7.3.4.4.9.4 1.4.2.6.1 1-.1 1.4zm-2.7-2.4c-.4 0-.7.2-.9.4-.2.2-.3.5-.3.9l.3.9c.2.2.5.4.9.5z" class="st1" />
    <path id="path110" d="M8.6 221.7v-.9l.7-.1v-.1l-.6-.6a2 2 0 0 1-.2-.9v-.2h.9v.4c0 .4.1.8.4 1 .2.2.6.4 1 .4h2.6v1z" class="st1" />
    <path id="path112" d="M8.6 218v-.9l.6-.1c-.4-.3-.7-.8-.7-1.4 0-.5.2-1 .5-1.3a2 2 0 0 1 1.4-.5h3v1h-2.8c-.4 0-.6.1-.9.3-.2.2-.3.5-.3.8 0 .4.1.7.4.9.3.2.6.3 1 .3h2.7v1H8.6Z" class="st1" />
    <path id="path114" d="M13.1 212.2c-.3.3-.6.5-1.1.5-.5 0-.8-.2-1.1-.5-.3-.3-.4-.8-.4-1.4v-1.3h-.2c-.3 0-.6.1-.7.3-.2.2-.2.4-.2.8 0 .6.3 1 .9 1.1v.9c-.5 0-.9-.2-1.2-.6a2 2 0 0 1-.5-1.4c0-.6.1-1.1.4-1.5.3-.4.7-.5 1.3-.5h2l.3-.1.1-.3v-.2h.9v.3l-.2.7-.5.4v.1c.5.3.8.8.8 1.5l-.6 1.2zm-.6-.7.2-.7c0-.4-.1-.7-.3-1-.2-.2-.5-.4-.9-.4h-.1v1.2c0 .3 0 .6.2.8.1.2.3.3.5.3l.4-.2z" class="st1" />
    <path id="path116" d="M6.6 207v-1h6.8v1z" class="st1" />
    <path id="path118" d="M6.6 204.5v-1h6.8v1z" class="st1" />
    <path id="path120" d="M14.5 202.1v-.5l-.1-.5-.4-.3-.2-.1h-.1l-5 1.9h-.1v-1l3.8-1.4v-.1l-3.8-1.3v-1h.1l5.6 2.1c.4.1.6.3.8.6l.2 1.1v.5z" class="st1" />
    <path id="path122" d="M6.7 194.6v-4.5h.9v3.5h2v-3h.9v3h2.8v1z" class="st1" />
    <path id="path124" d="M13.2 188.3c-.2.4-.5.6-.9.9-.4.2-.8.3-1.3.3s-.9-.1-1.3-.3a2 2 0 0 1-.9-.9l-.3-1.2c0-.5.1-.9.3-1.2.2-.4.5-.6.9-.9.4-.3.8-.3 1.3-.3s.9.1 1.3.3c.4.2.7.5.9.9l.3 1.2c0 .4-.1.9-.3 1.2zm-1-.2c.3-.3.4-.6.4-1s-.1-.8-.4-1c-.3-.3-.7-.4-1.2-.4s-.9.1-1.2.4c-.3.3-.4.6-.4 1s.1.8.4 1c.3.2.7.4 1.2.4s.9-.1 1.2-.4z" class="st1" />
    <path id="path126" d="M13.2 182.7c-.2.4-.5.6-.9.8-.4.2-.8.3-1.3.3s-.9-.1-1.3-.3c-.4-.2-.7-.5-.9-.8l-.3-1.2c0-.6.2-1.1.5-1.5.3-.4.8-.6 1.4-.7v.9c-.7.2-1 .6-1 1.2 0 .4.1.8.4 1 .3.2.7.4 1.2.4s.9-.1 1.2-.4c.3-.2.4-.6.4-1 0-.7-.3-1.1-1-1.3v-.9c.6.1 1.1.3 1.4.7.3.4.5.9.5 1.5s-.1 1-.3 1.3z" class="st1" />
    <path id="path128" d="M13 177.9a2 2 0 0 1-1.4.5h-3v-1h2.8c.4 0 .6-.1.9-.3.3-.2.3-.4.3-.8s-.1-.6-.4-.9c-.2-.2-.6-.3-1-.3H8.6v-1h4.8v.9l-.6.1v.1c.4.3.7.8.7 1.4 0 .5-.2.9-.5 1.3z" class="st1" />
    <path id="path130" d="M13 172.3c-.3.4-.7.6-1.2.7v-.9c.3-.1.5-.2.6-.4.2-.2.2-.5.2-.9l-.2-.8a.5.5 0 0 0-.5-.3l-.4.2-.2.5-.1 1c-.1.5-.2.8-.4 1-.2.2-.5.4-.9.4s-.8-.2-1.1-.5c-.3-.3-.4-.8-.4-1.4 0-.6.1-1 .4-1.4.3-.3.7-.5 1.1-.6v.9c-.5.1-.7.5-.7 1.1l.2.7c.1.2.3.2.5.2.3 0 .5-.2.5-.6l.2-1.1c.1-.4.2-.7.4-.9.2-.2.5-.3.9-.3.5 0 .8.2 1.1.5.3.3.4.8.4 1.5.1.6-.1 1.1-.4 1.4z" class="st1" />
    <path id="path132" d="M13.2 166.8a2 2 0 0 1-.9.9c-.4.2-.8.3-1.3.3s-.9-.1-1.3-.3c-.4-.2-.7-.5-.9-.8l-.3-1.2.3-1.2.8-.8c.3-.2.8-.3 1.2-.3h.4v3.5c.4-.1.8-.2 1-.5.2-.3.4-.6.4-1l-.2-.8c-.1-.2-.3-.3-.6-.4v-.9c.5.1.8.3 1.1.7.3.4.4.9.4 1.4.2.6.1 1.1-.1 1.4zm-2.7-2.4c-.4 0-.7.2-.9.4-.2.2-.3.5-.3.9l.3.9c.2.2.5.4.9.4z" class="st1" />
    <path id="path134" d="M13.2 161.5c-.2.3-.5.6-.9.8-.4.2-.8.3-1.3.3s-.9-.1-1.3-.3c-.4-.2-.7-.4-.9-.8-.2-.3-.3-.7-.3-1.2 0-.6.2-1 .6-1.4v-.1H6.6v-1h6.8v.8l-.6.1v.1c.5.3.7.8.7 1.5 0 .5-.1.9-.3 1.2zm-1-.3c.3-.2.4-.6.4-1s-.1-.8-.4-1c-.3-.2-.7-.4-1.2-.4s-.9.1-1.2.4c-.3.2-.4.6-.4 1s.1.7.4 1c.3.2.7.4 1.2.4s.9-.2 1.2-.4z" class="st1" />
        </g>
    <g id="g146">
    <g id="g140" class="st0">
    <path id="path138" d="m309.5 256.9-3.9 10.6h-9.8l-5.9 16.4h8.6l-3.9 10.6H286l-6.2 17.2h9.8l-3.8 10.6h-22.1l23.8-65.5h22z" class="st3" />
          </g>
    <g id="g144" class="st0">
    <path id="path142" fill="none" stroke="#31c99a" stroke-width="1.3" d="m309.5 256.9-3.9 10.6h-9.8l-5.9 16.4h8.6l-3.9 10.6H286l-6.2 17.2h9.8l-3.8 10.6h-22.1l23.8-65.5h22z" />
          </g>
        </g>
    <g id="g156">
    <g id="g150" class="st0">
    <path id="path148" d="M139.5 256.9v.8l-16.9 33.4-6.1 31.3h-12l4.8-22.3h-.8L97 322.4H85.6v-.8l18.1-35.1 5.9-29.5h11.3l-4.7 21.8h.8l11.2-21.8h11.3z" class="st3" />
          </g>
    <g id="g154" class="st0">
    <path id="path152" fill="none" stroke="#fecf33" stroke-width="1.3" d="M139.5 256.9v.8l-16.9 33.4-6.1 31.3h-12l4.8-22.3h-.8L97 322.4H85.6v-.8l18.1-35.1 5.9-29.5h11.3l-4.7 21.8h.8l11.2-21.8h11.3z" />
          </g>
        </g>
    <g id="g166">
    <g id="g160" class="st0">
    <path id="path158" d="M109.7 78h22.1l-3.9 10.6h-9.8l-5.9 16.4h8.2l-3.9 10.6h-8.2l-10.1 27.8H85.9Z" class="st3" />
          </g>
    <g id="g164" class="st0">
    <path id="path162" fill="none" stroke="#ff2e5e" stroke-width="1.3" d="M110.4 78h22.1l-3.9 10.6h-9.8l-5.9 16.4h8.2l-3.9 10.6H109l-10.1 27.8H86.6Z" />
          </g>
        </g>
    <g id="g176">
    <g id="g170" class="st0">
    <path id="path168" d="m299.9 78-20 54.8h10.2l-3.8 10.6h-22.5L287.6 78Z" class="st3" />
          </g>
    <g id="g174" class="st0">
    <path id="path172" fill="none" stroke="#83a9ff" stroke-width="1.3" d="m299.9 78-20 54.8h10.2l-3.8 10.6h-22.5L287.6 78Z" />
          </g>
        </g>
      </g>
    </svg>
  </div>
  <div class="col-md-5 d-flex flex-column mt-5">
    <h3 *ngIf="!isFlex && !teamMapUsers.firstName" id="mindsetTitle">
      <ng-container *ngIf="currentUser$ | async">{{(currentUser$ | async)?.firstName}}, </ng-container>your team has a collective preference for
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='C'">a Contained</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='O'">an Optimistic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='P'">a Prudent</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='E'">an Engaged</ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length==2"> and </ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3">, </ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='C'">Contained</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='O'">Optimistic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='P'">Prudent</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='E'">Engaged</ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3"> and </ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='C'">Contained</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='O'">Optimistic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='P'">Prudent</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='E'">Engaged</ng-container>

      mindset<ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3">s</ng-container>.
    </h3>
    <h3 *ngIf="isFlex && !teamMapUsers.firstName" id="behaviourTitle">
      <ng-container *ngIf="currentUser$ | async">{{(currentUser$ | async)?.firstName}}, </ng-container>your team has a collective preference for
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='F'">a Forceful</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='L'">a Logical</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='E'">an Empathic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='X'">an Expressive</ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length==2"> and </ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3">, </ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='F'">Forceful</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='L'">Logical</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='E'">Empathic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='X'">Expressive</ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3"> and </ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='F'">Forceful</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='L'">Logical</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='E'">Empathic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='X'">Expressive</ng-container>

      behavioural style<ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3">s</ng-container>.
    </h3>
    <h3 *ngIf="isFlex && teamMapUsers.firstName && teamMapUsers.inSpotlight">
      {{teamMapUsers.firstName}} leads with
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='F'">a Forceful</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='L'">a Logical</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='E'">an Empathic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='X'">an Expressive</ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1"> behavioural style with a strong preference for </ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='F'">Forceful</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='L'">Logical</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='E'">an Empathic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='X'">an Expressive</ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3"> and </ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='F'">Forceful</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='L'">Logical</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='E'">an Empathic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='X'">an Expressive</ng-container>

      style<ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3">s</ng-container>.
    </h3>
    <h3 *ngIf="!isFlex && teamMapUsers.firstName">
      {{teamMapUsers.firstName}} leads with
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='C'">a Contained</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='O'">an Optimistic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='P'">a Prudent</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight[0]=='E'">an Engaged</ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1"> mindset with a strong preference for </ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='C'">a Contained</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='O'">an Optimistic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='P'">a Prudent</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>1 && teamMapUsers.inSpotlight[1]=='E'">an Engaged</ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3"> and </ng-container>

      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='C'">Contained</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='O'">Optimistic</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='P'">Prudent</ng-container>
      <ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>2 && teamMapUsers.inSpotlight[2]=='E'">Engaged</ng-container>

      mindset<ng-container *ngIf="teamMapUsers.inSpotlight && teamMapUsers.inSpotlight.length>=3">s</ng-container>.
    </h3>

    <div class="body-copy mt-2" *ngFor="let desc of teamMapUsers.description">{{desc}}</div>
    <h3 class="mt-5">{{ isFlex ? "Behavioural Style" : "Mindset" }} key</h3>
    <div class="row mt-2">
      <div class="col d-flex align-items-center">
        <div class="key me-2"
             [class.forceful]="isFlex"
             [class.contained]="!isFlex"></div>
        <div class="body-copy flex-fill key-offset">
          {{ isFlex ? "Forceful" : "Contained" }}
        </div>
      </div>
      <div class="col d-flex align-items-center">
        <div class="key me-2"
             [class.logical]="isFlex"
             [class.optimistic]="!isFlex"></div>
        <div class="body-copy flex-fill key-offset">
          {{ isFlex ? "Logical" : "Optimistic" }}
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col d-flex align-items-center">
        <div class="key me-2"
             [class.expressive]="isFlex"
             [class.prudent]="!isFlex"></div>
        <div class="body-copy flex-fill key-offset">
          {{ isFlex ? "Expressive" : "Prudent" }}
        </div>
      </div>
      <div class="col d-flex align-items-center">
        <div class="key me-2"
             [class.empathic]="isFlex"
             [class.engaged]="!isFlex"></div>
        <div class="body-copy flex-fill key-offset">
          {{ isFlex ? "Empathic" : "Engaged" }}
        </div>
      </div>
    </div>
  </div>
</div>
