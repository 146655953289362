import { createSelector } from '@ngrx/store';
import { AppState, superAdminStateKey } from '../app.state';
import { MindflickOrganisationFilterState, SuperAdminState } from './super-admin.reducer';
import { MindflickOrganisation, UserBasicDetails } from 'src/app/shared/models';

export const selectSuperAdminState = (state: AppState) => state[superAdminStateKey];

export const selectMindflickPractitioners = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.mindflickPractitioners
)

export const selectCoachingClientInfo = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.clientInfo
)

export const selectCoachingFeedback = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.coachingFeedback
)

export const selectGroupedCoachingFeedback = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.groupedFeedback
)

export const selectQuestionnaires = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.questionnaires
);
export const selectQuestionnaireResponseManagementDetails = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.questionnaireResponseManagementDetails
);

export const selectUserSpotlightResponses = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.userSpotlightResponses
);

export const selectIsAddingMindflickCoach = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.isAddingMindflickCoach
);

export const selectIsAddingMindflickCoachSuccess = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.isAddingMindflickCoachSuccess
);

export const selectIsAddingMindflickCoachError = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.isAddingMindflickCoachError
);

export const selectMindflickOrganisations = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.mindflickOrganisations
);

export const selectMindflickOrganisationsLoading = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.mindflickOrganisationLoading
);

export const selectSelectedMindflickOrganisationId = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.selectedOrganisationId
);

export const selectSelectedMindflickOrganisation = createSelector(
  selectMindflickOrganisations,
  selectSelectedMindflickOrganisationId,
  (orgs: MindflickOrganisation[], orgId?: number) => {
    return orgs.find(x => x.id === orgId);
  }
);

export const selectMindflickOrganisationUsers = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.mindflickOrganisationUsers
);

export const selectSelectedMindflickOrganisationWithAccountAdminNames = createSelector(
  selectSelectedMindflickOrganisation,
  selectMindflickOrganisationUsers,
  (org: MindflickOrganisation | undefined, users: UserBasicDetails[]) => {
    if (!org)
      return undefined;

    const orgCopy = { 
      ...org, 
      mindflickAccounts: [ ...org.mindflickAccounts.map(a => ({
        ...a
      })) ] 
    };
    
    for (let i = 0; i < orgCopy.mindflickAccounts.length; i++) {
      const accountAdmin = users.find(u => u.ak === org.mindflickAccounts[i].adminAk);

      if (accountAdmin) {
        const accountCopy = { ...orgCopy.mindflickAccounts[i], adminName: accountAdmin.firstName + ' ' + accountAdmin.lastName };
        orgCopy.mindflickAccounts[i] = accountCopy;
      }
    }

    return orgCopy;
  }
);

export const selectMindflickOrganisationFilterState = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.mindflickOrganisationFilterState
);

export const selectFilteredMindflickOrganisations = createSelector(
  selectMindflickOrganisations,
  selectMindflickOrganisationFilterState,
  (orgs: MindflickOrganisation[], filter: MindflickOrganisationFilterState) => {
    let filteredOrgs: MindflickOrganisation[] = orgs;
    
    if (filter.searchTerm) {
      filteredOrgs = filteredOrgs.filter(o => o.name.toLocaleLowerCase().includes(filter.searchTerm!.toLocaleLowerCase()))
    }

    if (filter.isActive != undefined) {
      filteredOrgs = filteredOrgs.filter(o => o.isActive === filter.isActive);
    } 

    if (filter.type != undefined) {
      filteredOrgs = filteredOrgs.filter(o => o.mindflickAccounts.some(ma => ma.type == filter.type));
    }

    if (filter.mindflickLeadAk != undefined) {
      filteredOrgs = filteredOrgs.filter(o => o.organisationLeadAk === filter.mindflickLeadAk);
    }

    return filteredOrgs;
  }
);

export const selectFilteredMindflickOrgsSeatCount = createSelector(
  selectFilteredMindflickOrganisations,
  selectMindflickOrganisationFilterState,
  (orgs: MindflickOrganisation[], filter: MindflickOrganisationFilterState) => {
    const accounts = orgs.flatMap(o => o.mindflickAccounts.filter(a => (filter.isActive === undefined || (filter.isActive === true && a.status === 0) || (filter.isActive === false && a.status === 2)) && (filter.type === undefined || a.type === filter.type)));

    const seatCount =  accounts.map(x => x.seats).reduce((partial, s) => partial + s, 0);

    return seatCount;
  }
);