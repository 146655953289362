<div class="d-flex flex-column h-100 la-page text-white">
  <div class="header d-flex pt-5 pb-30">
    <div class="flex-fill d-flex flex-column">
      <div class="d-flex align-items-end">
        <div>
          <h1>Live Analytics</h1>
        </div>
        <div *ngIf="mindflickAccounts$ | async as accounts" class="btn-group ms-4" dropdown placement="bottom right">
          <h2 id="button-mindflick-accounts"
              class="d-flex"
              dropdownToggle
              type="button"
              aria-controls="dropdown-mindflick-accounts">
            {{ selectedAccount?.name }}
            <fa-icon class="fs-4 pot-dropdown d-flex align-items-center" [icon]="dropdownIcon" />
          </h2>
          <ul id="dropdown-mindflick-accounts"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="button-mindflick-accounts">
            <li *ngFor="let account of accounts"
                class="cursor-pointer"
                role="menuitem"
                (click)="onMindflickAccountChanged(account.accountId)">
              <div class="dropdown-item" href="#">
                {{ account.name }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <app-toggle-options [options]="timePeriodOptions" [selected]="selectedTimePeriod" (selectedChange)="onSelectedTimePeriodChanged($event)"></app-toggle-options>
    </div>
  </div>
  <div class="page-container flex-fill d-flex flex-column py-4">
    <app-la-summary [mindflickAccountId]="selectedAccountId" [filter]="selectedTimePeriod"></app-la-summary>
    <app-la-active-users [mindflickAccountId]="selectedAccountId" [filter]="selectedTimePeriod"></app-la-active-users>
    <hr class="my-35" />
    <div class="row gx-15 mb-15">
      <div class="w-50">
        <app-la-connected-profile-views [mindflickAccountId]="selectedAccountId" [filter]="selectedTimePeriod"></app-la-connected-profile-views>
      </div>
      <div class="w-50">
        <app-la-team-page-views [mindflickAccountId]="selectedAccountId" [filter]="selectedTimePeriod"></app-la-team-page-views>
      </div>
    </div>
    <app-la-feature-usage [mindflickAccountId]="selectedAccountId" [filter]="selectedTimePeriod"></app-la-feature-usage>
    <hr class="my-35" />
    <app-la-nudges [mindflickAccountId]="selectedAccountId" [filter]="selectedTimePeriod"></app-la-nudges>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex justify-content-center align-item-center h-100 mt-5">
    <div class="spinner-border text-primary">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
