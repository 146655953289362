import { Component, Input, inject } from '@angular/core';
import { take, zip } from 'rxjs';
import { AccountAdminService } from '../../../shared/services/account-admin.service';

interface LiveAnalyticsSummaryDTO {
  title: string;
  data: number;
}

@Component({
  selector: 'app-la-summary',
  templateUrl: './la-summary.component.html',
  styleUrl: './la-summary.component.scss'
})
export class LaSummaryComponent {

  accountAdminService = inject(AccountAdminService);

  _filter?: string;
  @Input()
  get filter(): string | undefined {
    return this._filter;
  }

  set filter(val: string | undefined) {
    this._filter = val;

    if (this._filter) {
      this.loadResults();
    }
  }

  _mindflickAccountId?: number;
  @Input()
  get mindflickAccountId(): number | undefined {
    return this._mindflickAccountId;
  }

  set mindflickAccountId(val: number | undefined) {
    this._mindflickAccountId = val;

    if (this._mindflickAccountId) {
      this.loadResults();
    }
  }

  totalSummaryData: LiveAnalyticsSummaryDTO[] = [];

  loadResults() {
    if (this.mindflickAccountId && this.filter) {
      zip(
      this.accountAdminService.getLiveAnalyticsActiveUsersSummary(this.mindflickAccountId, this.filter).pipe(take(1)),
      this.accountAdminService.getLiveAnalyticsConnectionsSummary(this.mindflickAccountId).pipe(take(1)),
      this.accountAdminService.getLiveAnalyticsTeamsSummary(this.mindflickAccountId).pipe(take(1))
      )
        .subscribe(([users, connections, teams]) => {
          this.totalSummaryData = [];
          this.totalSummaryData.push({ title: 'Total users', data: users });
          this.totalSummaryData.push({ title: 'Total connections', data: connections });
          this.totalSummaryData.push({ title: 'Total teams', data: teams });
        });
    }
  }

  trackItem(_: number, item: LiveAnalyticsSummaryDTO) {
    return item.title;
  }
}
