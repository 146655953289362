import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AccountAdminService } from 'src/app/shared/services/account-admin.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { SuperAdminService } from 'src/app/shared/services/super-admin.service';
import * as AccountActions from './account.actions';
import * as LayoutActions from '../layout/actions/layout.actions';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { noOpAction } from '../layout/actions/layout.actions';
import { UpdateMindflickOrganisationAccount } from '../super-admin/super-admin.actions';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class AccountEffects {
  getAllAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.GetAllMindflickAccounts.Request),
      mergeMap((action) =>
        this.superAdminService.getMindflickAccounts().pipe(
          map((resp) => {
            return AccountActions.GetAllMindflickAccounts.Success({ accounts: resp });
          }
          ),
          catchError((error) =>
            of(AccountActions.GetAllMindflickAccounts.Fail({ error }))
          )
        )
      )
    )
  );

  getUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.GetUserMindflickAccount.Request),
      mergeMap((action) =>
        this.accountService.getUserMindflickAccount().pipe(
          map((resp) =>
            AccountActions.GetUserMindflickAccount.Success({
              account: resp,
            })
          ),
          catchError((error) =>
            of(AccountActions.GetUserMindflickAccount.Fail({ error }))
          )
        )
      )
    )
  );

  getAllAccountsForCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.GetMindflickAccountsForCurrentUser.Request),
      mergeMap((action) =>
        this.accountService.getAccountsForCurrentUser().pipe(
          map((resp) =>
            AccountActions.GetMindflickAccountsForCurrentUser.Success({
              accounts: resp,
            })
          ),
          catchError((error) =>
            of(
              AccountActions.GetMindflickAccountsForCurrentUser.Fail({ error })
            )
          )
        )
      )
    )
  );

  getAllAccountsForCurrentUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.GetMindflickAccountsForCurrentUser.Success),
        mergeMap((action) => {
          if (!action.accounts.length) {
            return this.oidcService.logoff();
          }

          return of(noOpAction);
        })
      ),
    { dispatch: false }
  );

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.AddMindflickAccount.Request),
      mergeMap((action) =>
        this.superAdminService.addMindflickAccount(action.account).pipe(
          mergeMap((resp) => {
            this.modalService.hide(action.modalId);
            return [
              AccountActions.AddMindflickAccount.Success({ account: resp }),
              LayoutActions.DisplaySnackbarAlert.SetAlert({ alert: { message: `Created ${resp.name}!`, alertType: 'success' } })
            ];
          }
          ),
          catchError((error) =>
            of(AccountActions.AddMindflickAccount.Fail({ error }))
          )
        )
      )
    )
  );

  updateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.UpdateMindflickAccount.Request),
      mergeMap((action) =>
        this.superAdminService.updateMindflickAccount(action.account).pipe(
          mergeMap((resp) => {
            this.modalService.hide(action.modalId);
            return [
              AccountActions.UpdateMindflickAccount.Success({ account: resp }),
              UpdateMindflickOrganisationAccount({ organisationId: resp.mindflickOrganisationId, mindflickAccount: resp }),
              LayoutActions.DisplaySnackbarAlert.SetAlert({ alert: { message: `Updated ${resp.name}'s account details!`, alertType: 'success' } })
            ];
          }
          ),
          catchError((error) =>
            of(AccountActions.UpdateMindflickAccount.Fail({ error }))
          )
        )
      )
    )
  );

  addCreditPot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.AddCreditPot.Request),
      mergeMap((action) =>
        this.superAdminService.addCreditPot(action.creditPot).pipe(
          map((resp) =>
            AccountActions.AddCreditPot.Success({ creditPot: resp })
          ),
          catchError((error) => of(AccountActions.AddCreditPot.Fail({ error })))
        )
      )
    )
  );

  updateCreditPot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.UpdateCreditPot.Request),
      mergeMap((action) =>
        this.superAdminService.updateCreditPot(action.creditPot).pipe(
          map((resp) =>
            AccountActions.UpdateCreditPot.Success({ creditPot: resp })
          ),
          catchError((error) =>
            of(AccountActions.UpdateCreditPot.Fail({ error }))
          )
        )
      )
    )
  );

  deactivateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.DeactivateUser.Request),
      mergeMap((action) =>
        this.accountAdminService.deactivateUser(action.userAK, action.accountId).pipe(
          mergeMap((resp) => {
            return [
              AccountActions.DeactivateUser.Success({ userAK: resp }),
              LayoutActions.DisplaySnackbarAlert.SetAlert({
                alert: {
                  alertType: "success",
                  messageHeader: "Success!",
                  message: `You deactivated a user.`
                },
              })
            ];
          }
          ),
          catchError((error) =>
            of(AccountActions.DeactivateUser.Fail({ error }))
          )
        )
      )
    )
  );

  getIsUserInvitesEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.GetUserInvitesEnabled.Request),
      mergeMap((action) =>
        this.accountService.getIsUserInvitesEnabled().pipe(
          map((resp) =>
            AccountActions.GetUserInvitesEnabled.Success({
              isUserInvitesEnabled: resp,
            })
          ),
          catchError((error) =>
            of(AccountActions.GetUserInvitesEnabled.Fail({ error }))
          )
        )
      )
    )
  );

  getOrgTwoFactorEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.GetOrgTwoFactorRequired.Request),
      mergeMap((action) =>
        this.accountService.getOrgTwoFactorRequired().pipe(
          map((resp) =>
            AccountActions.GetOrgTwoFactorRequired.Success({
              orgTwoFactorRequired: resp
            })
          ),
          catchError((error) =>
            of(AccountActions.GetOrgTwoFactorRequired.Fail({ error }))
          )
        ))
    ));

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private accountAdminService: AccountAdminService,
    private superAdminService: SuperAdminService,
    private oidcService: OidcSecurityService,
    private modalService: BsModalService
  ) {}
}
