<div class="rounded feature-usage">
  <div class="d-flex justify-content-between">
    <h2 class="mb-15">Feature usage</h2>
    <app-btn-tertiary [icon]=" isFeatureUsageExpanded ? collapseIcon : dropdownIcon" [purple300]="true" (click)="isFeatureUsageExpanded = !isFeatureUsageExpanded">{{ isFeatureUsageExpanded ? 'Collapse' : 'Expand' }} view</app-btn-tertiary>
  </div>
  <p>Number of times strengths, team meetings and pin coding features were used</p>
  <div class="chart-container">
    <ngx-charts-bar-vertical-stacked [scheme]="combinedColorScheme"
                                     [results]="results"
                                     [xAxis]="true"
                                     [yAxis]="true"
                                     [legend]="true"
                                     [showXAxisLabel]="false"
                                     [showYAxisLabel]="true"
                                     [yAxisLabel]="'Number of events'"
                                     [gradient]="true">
    </ngx-charts-bar-vertical-stacked>
  </div>

  <ng-container *ngIf="isFeatureUsageExpanded">
    <hr class="my-35" />
    <div class="row gx-10">
      <div *ngFor="let graph of breakdownResults; let i = index" class="col-4">
        <div class="d-flex flex-column bg-grey rounded p-3 feature-usage-item">
          <h2>{{ graph.title }}</h2>
          <p>{{ graph.description }}</p>
          <div class="d-flex flex-fill">
            <ngx-charts-bar-vertical 
                                     [scheme]="breakdownSchemes[i]"
                                     [results]="graph.data"
                                     [xAxis]="true"
                                     [yAxis]="true"
                                     [legend]="false"
                                     [showXAxisLabel]="false"
                                     [showYAxisLabel]="false"
                                     [legendTitle]="''"
                                     [gradient]="true">
            </ngx-charts-bar-vertical>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
