import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AppState } from '../app.state';
import * as GrowthActions from '../growth/growth.actions';
import {
    GetTopOrganisationNudges
} from '../growth/growth.actions';
import * as SignalrActions from '../signalr/signalr.actions';
import * as TooltipActions from '../tooltip/tooltip.actions';
import { selectAllTooltipsLoaded } from '../tooltip/tooltip.selector';
import { selectCurrentUser } from '../user/user.selector';
import * as ProfileActions from './profile.actions';
import { selectLoadedProfiles } from './profile.selector';

@Injectable()
export class ProfileEffects {
  getMyProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.GetMyProfile.Request),
      withLatestFrom(this.store.select(selectCurrentUser)),
      mergeMap(([action, currentUser]) => {
        if (currentUser) {
          return this.profileService.getProfileForUser(currentUser.id).pipe(
            map((resp) =>
              ProfileActions.GetMyProfile.Success({ profile: resp })
            ),
            catchError((error) => {
              var message = 'User has not answered all questions yet.';
              if (error.error == message) {
                this.store.dispatch(ProfileActions.GetProfileDetails.Request());
              }
              return of(ProfileActions.GetMyProfile.Fail({ error }));
            })
          );
        }

        return of(
          ProfileActions.GetProfileForUser.Fail({
            error: 'Current User is not yet loaded',
          })
        );
      })
    )
  );

  getProfileDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.GetProfileDetails.Request),
      mergeMap((action) =>
        this.profileService.getProfileDetails().pipe(
          map((resp) => {
            this.router.navigate([
              `survey/sign-up/${resp.uniqueRef}/${resp.spotlightResponseId}`,
            ]);
            return ProfileActions.GetProfileDetails.Success();
          }),
          catchError((error) =>
            of(ProfileActions.GetProfileDetails.Fail({ error }))
          )
        )
      )
    )
  );

  getMyProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.GetMyProfile.Success),
      withLatestFrom(this.store.select(selectAllTooltipsLoaded)),
      mergeMap(([action, tooltipsLoaded]) =>
        !action.profile.error && !tooltipsLoaded
          ? [
            // Get the dashboard content
            GetTopOrganisationNudges.Request(),
            // Start by getting some useful UI components
            GrowthActions.GetWorkOns.Request(),
            TooltipActions.GetTooltips.Request(),
            //Once they are complete we can get some bigger requests
            //See GrowthActions.GetWorkOns.Success for work on history request
            //See TooltipActions.GetTooltips.Success for next requests
          ]
          : !action.profile.error && tooltipsLoaded
            ? [
              GetTopOrganisationNudges.Request(),
              GrowthActions.GetWorkOns.Request(),
            ]
            : action.profile.error && !tooltipsLoaded
              ? [
                GetTopOrganisationNudges.Request(),
                TooltipActions.GetTooltips.Request(),
              ]
              : []
      )
    )
  );

  getProfileForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.GetProfileForUser.Request),
      withLatestFrom(
        this.store.select(selectLoadedProfiles),
        this.store.select(selectCurrentUser)
      ),
      mergeMap(([action, profiles, currentUser]) => {

        var loadedProfile = profiles.find((x) => x.userAK === action.userId);

        if(action.forceLoadProfile){
          loadedProfile = undefined
        }

        if (!loadedProfile) {
          return this.profileService.getProfileForUser(action.userId).pipe(
            mergeMap((resp) =>
              currentUser?.id! != action.userId
                ? [
                  ProfileActions.GetProfileForUser.Success({
                    profile: resp,
                    isCurrentUser: false,
                  }),
                ]
                : [
                  ProfileActions.GetProfileForUser.Success({
                    profile: resp,
                    isCurrentUser: true,
                  }),
                ]
            ),
            catchError((error) =>
              of(ProfileActions.GetProfileForUser.Fail({ error }))
            )
          );
        } else {
          return of(
            currentUser?.id! != action.userId
              ? ProfileActions.GetProfileForUser.Success({
                profile: loadedProfile,
                isCurrentUser: false,
              })
              : ProfileActions.GetProfileForUser.Success({
                profile: loadedProfile,
                isCurrentUser: true,
              })
          );
        }
      })
    )
  );

  saveProfileSectionSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.SaveProfileSectionSelection.Request),
      mergeMap((action) =>
        this.profileService.saveProfileSectionSelection(action.body).pipe(
          map((_) =>
            ProfileActions.SaveProfileSectionSelection.Success({
              body: action.body,
              sectionId: action.sectionId,
            })
          ),
          catchError((error) =>
            of(ProfileActions.SaveProfileSectionSelection.Fail({ error }))
          )
        )
      )
    )
  );

  saveRecognizedStrengths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.SaveRecognizedStrengths.Request),
      mergeMap((action) =>
        this.profileService
          .saveSpottedStrength(
            action.saveStrengthComment
          )
          .pipe(
            withLatestFrom(
              this.store.select(selectCurrentUser)
            ),
            mergeMap(([resp, user]) => {
              this.modalService.hide(action.modalRef);
              return [
                ProfileActions.SaveRecognizedStrengths.Success({
                  newStrengthComment: { ...resp, strengthId: action.saveStrengthComment.strengthId, commentorAK: user?.id ?? 0 }
                }),
                SignalrActions.NotifyUserOfStrengthSpotted.Request({
                  userAK: action.saveStrengthComment.strengthOwnerId,
                  strengthId: action.saveStrengthComment.strengthId,
                }),
              ];
            }
            ),
            catchError((error) =>
              of(ProfileActions.SaveRecognizedStrengths.Fail({ error }))
            )
          )
      )
    )
  );

  getUserTopTip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.GetTopTipToConnect.Request),
      mergeMap((action) =>
        this.userService.getPinnedTipForUser(action.viewedUserAk).pipe(
          map((resp) =>
            ProfileActions.GetTopTipToConnect.Success({ topTip: resp })
          ),
          catchError((error) =>
            of(ProfileActions.GetTopTipToConnect.Fail({ error }))
          )
        )
      )
    )
  );

  setUserTopTip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.SetTopTip.Request),
      mergeMap((action) =>
        this.userService.setTopTip(action.pinnedTip).pipe(
          map(
            (resp) => ProfileActions.SetTopTip.Success({ topTip: resp }),
            catchError((error) => of(ProfileActions.SetTopTip.Fail({ error })))
          )
        )
      )
    )
  );

  removeUserTopTip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.RemoveTopTip.Request),
      mergeMap((action) =>
        this.userService.removeTopTip(action.pinnedTip).pipe(
          map((resp) =>
            ProfileActions.RemoveTopTip.Request({ pinnedTip: resp })
          ),
          catchError((error) => of(ProfileActions.RemoveTopTip.Fail({ error })))
        )
      )
    )
  );


  saveDebriefSelectedProfileItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.SaveDebriefSelectedProfileItem.Request),
      mergeMap((action) =>
        this.profileService.saveSelectedProfileItems(action.items).pipe(
          map((resp) =>
            ProfileActions.SaveDebriefSelectedProfileItem.Success()
          ),
          catchError((error) => of(ProfileActions.SaveDebriefSelectedProfileItem.Fail({ error })))
        )
      )
    )
  );


  processDebreifReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.ProcessDebriefReport.Request),
      mergeMap((action) =>
        this.profileService.processDebriefReport(action.spotlightResponseId).pipe(
          map(() =>
            ProfileActions.ProcessDebriefReport.Success()
          ),
          catchError((error) => of(ProfileActions.ProcessDebriefReport.Fail({ error })))
        )
      )
    )
  );


  markStrengthAsAcknowledged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.SayThanksStrengthSpot.Request),
      mergeMap((action) =>
        this.profileService
          .markStrengthCommentAsAcknowledged(
            action.strengthComment.id
          )
          .pipe(
            withLatestFrom(
              this.store.select(selectCurrentUser)
            ),
            mergeMap(([resp, user]) => {
              return [
                ProfileActions.SayThanksStrengthSpot.Success({
                  strengthComment: resp
                }),
              ];
            }
            ),
            catchError((error) =>
              of(ProfileActions.SayThanksStrengthSpot.Fail({ error }))
            )
          )
        )
      )
    );


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private profileService: ProfileService,
    private router: Router,
    private userService: UserService,
    private modalService: BsModalService
  ) {}
}
