import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  Chart,
  ChartConfiguration,
  ChartData,
  ChartType,
  ChartTypeRegistry,
  TooltipItem,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/+state/app.state';
import { TeamMapUser, TeamMapUsers, User } from 'src/app/shared/models';
import { Subject } from 'rxjs';
import * as TeamActions from '../../../+state/team/team.actions';
import {
  selectCurrentUser,
  selectSelectedTeamMapUserCope,
  selectSelectedTeamMapUserFlex,
  selectUserById,
  selectUsersForTeamMap,
} from '../../../+state/user/user.selector';
import { take, takeUntil } from 'rxjs/operators';
import { SetSelectedTeamMapUser } from '../../../+state/team/team.actions';
import { environment } from '../../../../environments/environment';
import { selectProfileTeamMapUsers } from 'src/app/+state/profile/profile.selector';

enum LetterHighlight {
  None,
  Left,
  Top,
  Right,
  Bottom,
}

@Component({
  selector: 'app-team-map',
  templateUrl: './team-map.component.html',
  styleUrls: ['./team-map.component.scss'],
})
export class TeamMapComponent implements OnInit, OnChanges {
  private _chart: BaseChartDirective | undefined;
  @ViewChild(BaseChartDirective) set chart(
    chart: BaseChartDirective | undefined
  ) {
    if (chart) {
      this._chart = chart;
      this.updateChart();
    }
  }
  get chart() {
    return this._chart;
  }

  destroyed$ = new Subject<boolean>();
  currentUser$ = this.store.select(selectCurrentUser);

  @Input() isFlex: boolean = true;
  @Input() flex?: string;
  @Input() cope?: string;
  @Input() isProfile?: boolean = false;

  cope$ = this.store.select(selectSelectedTeamMapUserCope);
  flex$ = this.store.select(selectSelectedTeamMapUserFlex);
  teamMapUsers$ = this.store.select(selectUsersForTeamMap);
  teamMapProfileUser$ = this.store.select(selectProfileTeamMapUsers);

  teamMapUsers: TeamMapUsers = {
    members: [],
  };
  letterHighlight: LetterHighlight = LetterHighlight.None;

  LetterHighlight = LetterHighlight;

  selectedGroup: TeamMapUser | undefined;

  plugins = [ChartDataLabels];

  teamMapCoords: { x: number; y: number }[] = [];
  teamUsers: TeamMapUser[] = [];

  public scatterChartLabels: string[] = [];
  scatterChartColours: string[] = [];

  secondPreferenceFlex: string = '';
  secondPreferenceCope: string = '';

  getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(10%, -50%)';
      tooltipEl.style.transition = 'all .1s ease';
      tooltipEl.style.zIndex = '99999';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  externalTooltipHandler = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = this.getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
    //context

    const tableHead = document.createElement('thead');
    const tableBody = document.createElement('tbody');

    // Set Text
    if (tooltip.body) {
      const teamMapUsersToDisplay = this.setTooltipUsers(
        tooltip.dataPoints[0].dataIndex
      );

      teamMapUsersToDisplay.forEach((teamMapUser: TeamMapUser) => {
        let user: User = this.getSingleUser(teamMapUser.userId);

        const tr = document.createElement('tr');

        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = '0';

        const td = this.createUserDisplay(user);

        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
    }
    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    if (tableRoot && tableRoot.firstChild) {
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  public scatterChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        font: {
          size: 24,
          family: 'FKScreamerSlanted',
        },
        color: '#FFF',
        formatter: (value, context) => {
          return this.scatterChartLabels[context.dataIndex];
        },
      },
      tooltip: {
        enabled: false,
        external: this.externalTooltipHandler,
      },
    },
    scales: {
      x: {
        display: false,
        max: 1,
        min: -1,
      },
      y: {
        display: false,
        max: 1,
        min: -1,
      },
    },
  };

  public scatterChartData: ChartData<'scatter'> = {
    labels: this.scatterChartLabels,
    datasets: [
      {
        data: this.teamMapCoords,
        pointRadius: 20,
        pointHoverRadius: 20,
        borderWidth: 2,
        pointBackgroundColor: (context) => {
          return this.scatterChartColours[context.dataIndex];
        },
        pointHoverBackgroundColor: (context) => {
          return this.setHoverColour(<{ x: number; y: number }>context.raw);
        },
        pointHoverBorderColor: 'white',
        borderColor: '#FFFFFF',
      },
    ],
  };

  public scatterChartType: ChartType = 'scatter';

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    if (this.isProfile) {
      this.teamMapProfileUser$.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
        this.teamMapUsers = users;
      });
    } else {
      this.teamMapUsers$.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
        this.teamMapUsers = users;
      });
    }


    this.cope$.pipe(takeUntil(this.destroyed$)).subscribe((cope) => {
      this.secondPreferenceCope = cope[1];
    });

    this.flex$.pipe(takeUntil(this.destroyed$)).subscribe((flex) => {
      this.secondPreferenceFlex = flex[1];
    });

    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateChart();
  }

  toggleFlexCope() {
    this.store.dispatch(TeamActions.ToggleTeamMapType());
  }

  private updateChart() {

    this.teamMapCoords = this.isFlex
      ? this.teamMapUsers.members.map((x) => x.flexCoords)
      : this.teamMapUsers.members.map((x) => x.copeCoords);

    if (this.isProfile) {
      this.adjustOverlappingPoints();
    }
    this.teamUsers = this.teamMapUsers.members.map((x) => {
      return <TeamMapUser>{
        userId: x.userId,
        copeCoords: x.copeCoords,
        flexCoords: x.flexCoords,
      };
    });


    //this.replaceNestedDuplicateInitials();

    this.scatterChartLabels = this.teamMapUsers.members.map((x) => x.initials);
    this.scatterChartColours = this.getDataLabelColours();

    if (this.chart && this.chart.chart) {
      this.scatterChartData.datasets[0].data = this.teamMapCoords;
      this.chart.chart.update();
    }

    // Set letter highlight
    if (this.isFlex && this.flex) {
      switch (this.flex.substring(0, 2).toUpperCase()) {
        case 'FX':
        case 'XF':
          this.letterHighlight = LetterHighlight.Left;
          break;
        case 'FL':
        case 'LF':
          this.letterHighlight = LetterHighlight.Top;
          break;
        case 'LE':
        case 'EL':
          this.letterHighlight = LetterHighlight.Right;
          break;
        case 'EX':
        case 'XE':
          this.letterHighlight = LetterHighlight.Bottom;
          break;
        default:
          this.letterHighlight = LetterHighlight.None;
          break;
      }
    } else if (!this.isFlex && this.cope) {
      switch (this.cope.substring(0, 2).toUpperCase()) {
        case 'CP':
        case 'PC':
          this.letterHighlight = LetterHighlight.Left;
          break;
        case 'CO':
        case 'OC':
          this.letterHighlight = LetterHighlight.Top;
          break;
        case 'OE':
        case 'EO':
          this.letterHighlight = LetterHighlight.Right;
          break;
        case 'EP':
        case 'PE':
          this.letterHighlight = LetterHighlight.Bottom;
          break;
        default:
          this.letterHighlight = LetterHighlight.None;
          break;
      }
    }
  }

  private adjustOverlappingPoints() {
    let p1 = this.teamMapCoords[0];
    let p2 = this.teamMapCoords[1];

    if (p1.x == p2.x && p1.y == p2.y) {
      let newCoords = [
        {
          x: p1.x + 0.05,
          y: p1.y + 0.05,
        },
        {
          x: p2.x - 0.05,
          y: p2.y - 0.05,
        },
      ];
      this.teamMapCoords = newCoords;
    }
  }

  private getDataLabelColours(): string[] {
    let colours: string[] = [];

    // Iterate through coords and determine quadrant
    this.teamMapCoords.forEach((coords) => {
      if (!coords) return;

      // Forceful/Contained
      if (coords.x < 0 && coords.y > 0) {
        colours.push(this.isFlex ? '#FF2E5E' : '#000');
      }

      // Logical/Optimistic
      if (coords.x > 0 && coords.y > 0) {
        colours.push(this.isFlex ? '#83A9FF' : '#FF5F00');
      }

      // Empathic/Engaged
      if (coords.x > 0 && coords.y < 0) {
        colours.push(this.isFlex ? '#31C99A' : '#878787');
      }

      // Expressive/Prudent
      if (coords.x < 0 && coords.y < 0) {
        colours.push(this.isFlex ? '#FECF33' : '#7000DD');
      }
    });

    return colours;
  }

  setHoverColour(coords: { x: number; y: number }) {
    if (this.isFlex) {
      var userAK = this.teamUsers.find(
        (x) => x.flexCoords.x == coords.x && x.flexCoords.y == coords.y
      )?.userId!;

      // userAK = -1 for grouped icons
      if (userAK == -1) {
        userAK = this.teamMapUsers.members.find(
          (x) => x.flexCoords.x == coords.x && x.flexCoords.y == coords.y
        )?.nestedUsers![0]!.userId!;
      }

      this.store.dispatch(SetSelectedTeamMapUser({ userAK }));

      switch (this.secondPreferenceFlex) {
        case 'F':
          return '#FF2E5E';
        case 'L':
          return '#83A9FF';
        case 'E':
          return '#31C99A';
        case 'X':
          return '#FECF33';
      }
    } else if (!this.isFlex) {
      var userAK = this.teamUsers.find(
        (x) => x.copeCoords.x == coords.x && x.copeCoords.y == coords.y
      )?.userId!;

      if (userAK == -1) {
        userAK = this.teamMapUsers.members.find(
          (x) => x.copeCoords.x == coords.x && x.copeCoords.y == coords.y
        )?.nestedUsers![0]!.userId!;
      }

      this.store.dispatch(SetSelectedTeamMapUser({ userAK }));

      switch (this.secondPreferenceCope) {
        case 'C':
          return '#000';
        case 'O':
          return '#FD5F00';
        case 'P':
          return '#7000DD';
        case 'E':
          return '#868786';
      }
    }
    return 'black';
  }

  getSingleUser(userId: number): User {
    let user!: User;

    this.store
      .select(selectUserById(userId))
      .pipe(take(1))
      .subscribe((x: User) => {
        user = x;
      });
    return user;
  }

  private createUserDisplay(user: User) {
    const td = document.createElement('td');
    td.style.borderWidth = '0';

    const div = document.createElement('div');
    div.style.background = 'grey';
    div.style.borderColor = 'black';
    div.style.borderWidth = '2px';
    div.style.marginRight = '5px';
    div.style.height = '10px';
    div.style.width = '10px';
    div.style.display = 'inline';
    td.appendChild(div);

    const image = document.createElement('img');
    image.style.width = '15px';
    image.style.marginRight = '5px';
    image.src =
      environment.publicStorageUrl +
      'photos/thumb/' +
      user.strId +
      '_' +
      user.photoLastModified +
      '.jpg';
    td.appendChild(image);

    const text = document.createTextNode(`${user.firstName} ${user.lastName}`);
    td.appendChild(text);

    return td;
  }

  private setTooltipUsers(dataIndex: number) {
    return (
      this.teamMapUsers.members[dataIndex].nestedUsers! ?? [
        this.teamMapUsers.members[dataIndex],
      ]
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
