<div class="toggle-container">
  <div class="toggle-slider" [style.left.%]="sliderPositionPercent" [style.width.%]="100 / options.length"></div>
  <button
    *ngFor="let option of options"
    class="toggle-button body-copy"
    [class.active]="selected === option.value"
    (click)="onOptionClick(option.value)">
    {{ option.description }}
  </button>
</div>
