<div class="row gx-15">
  <div class="d-flex col-4 flex-column">
    <app-la-summary-card [title]="'Total nudges seen'" [count]="nudgesSeen"></app-la-summary-card>
    <div class="d-flex flex-column flex-fill rounded mt-15 list-padding">
      <h2>Nudge ratings</h2>
      <p>Nudges that were rated in a positive way</p>
      <div class="d-flex flex-fill pt-2">
        <ngx-charts-gauge [results]="nudgeUsefulPercent"
                          [startAngle]="0"
                          [angleSpan]="360"
                          [bigSegments]="0"
                          [smallSegments]="0"
                          [valueFormatting]="formatGaugeValue"
                          [scheme]="allOrangeScheme"
                          [margin]="[0, 0, 0, 0]">
        </ngx-charts-gauge>
      </div>
      
    </div>
    
  </div>
  <div class="col-4">
    <div class="d-flex flex-column rounded list-padding">
      <h2 class="mb-3">Top Nudges</h2>
      <div class="row gy-10">
        <app-work-on-card-small *ngFor="let nudge of topNudges; trackBy: trackNudge"
                                [id]="-1"
                                [title]="nudge.title"
                                [imageUrl]="nudge.squareImageUrl"
                                [growthType]="GrowthType.WorkOn"
                                [disableShowWorkOn]="false"
                                [hasRightMargin]="false"
                                [showSummary]="false"
                                [minWidthPx]="0"
                                [widthVw]="0"
                                [isSmall]="true"
                                (click)="showNudgeDetails(nudge)"></app-work-on-card-small>
      </div>
    </div>
  </div>

  <div class="col-4">
    <div class="d-flex flex-column rounded list-padding">
      <h2 class="mb-3">Top Work Ons</h2>
      <div class="row gy-10">
        <app-work-on-card-small *ngFor="let workOn of topWorkOns; trackBy: trackWorkOn"
                                [id]="workOn.id"
                                [title]="workOn.title"
                                [summary]="workOn.summary"
                                [imageUrl]="workOn.portalUpcomingImageUrl"
                                [growthType]="GrowthType.WorkOn"
                                [hasRightMargin]="false"
                                [showSummary]="false"
                                [minWidthPx]="0"
                                [isSmall]="true"
                                [widthVw]="0"></app-work-on-card-small>
      </div>

    </div>
  </div>
</div>
