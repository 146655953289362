import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { SuperAdminService } from 'src/app/shared/services/super-admin.service';
import * as SuperAdminActions from './super-admin.actions';
import { DisplaySnackbarAlert } from '../layout/actions/layout.actions';
import { AddDeactivatedUsersModalComponent } from 'src/app/shared/components/add-deactivated-users-modal/add-deactivated-users-modal.component';
import * as LayoutActions from '../layout/actions/layout.actions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddUserSuccessModalComponent } from 'src/app/shared/components/add-user-success-modal/add-user-success-modal.component';

@Injectable()
export class SuperAdminEffects {
  getMindflickPractitioners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.GetMindflickPractitioners.Request),
      mergeMap((action) =>
        this.superAdminService
          .getMindflickPractitioners(action.clientName)
          .pipe(
            map((resp) =>
              SuperAdminActions.GetMindflickPractitioners.Success({
                practitioners: resp,
              })
            ),
            catchError((error) =>
              of(SuperAdminActions.GetMindflickPractitioners.Fail({ error }))
            )
          )
      )
    )
  );

  getCoachingClientInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.GetCoachingClientInfo.Request),
      mergeMap((action) =>
        this.superAdminService.getCoachingClientNames(action.dateRange, action.coachAK).pipe(
          map((resp) =>
            SuperAdminActions.GetCoachingClientInfo.Success({
              clientInfo: resp,
            })
          ),
          catchError((error) =>
            of(SuperAdminActions.GetCoachingClientInfo.Fail({ error }))
          )
        )
      )
    )
  );

  getCoachFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.GetCoachingFeedback.Request),
      mergeMap((action) =>
        this.superAdminService
          .getCoachingFeedback(action.dateRange, action.coachAk, action.clientName)
          .pipe(
            map((resp) =>
              SuperAdminActions.GetCoachingFeedback.Success({
                coachingFeedback: resp,
              })
            ),
            catchError((error) =>
              of(SuperAdminActions.GetCoachingFeedback.Fail({ error }))
            )
          )
      )
    )
  );

  getGroupedCoachFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.GetGroupedCoachingFeedback.Request),
      mergeMap((action) =>
        this.superAdminService
          .getGroupedCoachingFeedback(action.dateRange, action.isPractitioner)
          .pipe(
            map((resp) =>
              SuperAdminActions.GetGroupedCoachingFeedback.Success({
                feedback: resp,
              })
            ),
            catchError((error) =>
              of(SuperAdminActions.GetGroupedCoachingFeedback.Fail({ error }))
            )
          )
      )
    )
  );


  getQuestionnaires$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.GetQuestionnaires.Request),
      mergeMap(() =>
        this.superAdminService
          .getQuestionnaires()
          .pipe(
            map((resp) =>
              SuperAdminActions.GetQuestionnaires.Success({
                questionnaires: resp,
              })
            ),
            catchError((error) =>
              of(SuperAdminActions.GetQuestionnaires.Fail({ error }))
            )
          )
      )
    )
  );

  addQuestionnaires$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.AddQuestionnaires.Request),
      mergeMap((action) =>
        this.superAdminService
          .addQuestionnaires(action.questionnaireInvites)
          .pipe(
            map((_) =>
              SuperAdminActions.AddQuestionnaires.Success()
            ),
            catchError((error) =>
              of(SuperAdminActions.AddQuestionnaires.Fail({ error }))
            )
          )
      )
    )
  );

  getQuestionnaireResponseManagementDTO$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.GetQuestionnaireResponseManagementDetails.Request),
      mergeMap((action) =>
        this.superAdminService
          .getQuestionnaireManagementDTO(action.spotlightResponseId)
          .pipe(
            map((resp) =>
              SuperAdminActions.GetQuestionnaireResponseManagementDetails.Success({ questionnaireResponseManagementDTO: resp })
            ),
            catchError((error) =>
              of(SuperAdminActions.GetQuestionnaireResponseManagementDetails.Fail({ error }))
            )
          )
      )
    )
  );

  sendEngagementPushNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.SendEngagementPushNotification.Request),
      mergeMap((action) =>
        this.superAdminService
          .sendEngagementPushNotification(action.payload)
          .pipe(
            mergeMap((resp) => [
              SuperAdminActions.SendEngagementPushNotification.Success(),
              DisplaySnackbarAlert.SetAlert({
                alert: {
                  messageHeader: 'Success!',
                  message: `Engagement push notification sent to ${action.payload.userAks.length} user(s)`,
                  alertType: 'success',
                },
              }),
            ]),
            catchError((error) =>
              of(
                SuperAdminActions.SendEngagementPushNotification.Fail({ error })
              )
            )
          )
      )
    )
  );

  getUsersSpotlightResponses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.GetUserSpotlightResponses.Request),
      mergeMap((action) =>
        this.superAdminService
          .getUserSpotlightResponses(action.userAK)
          .pipe(
            map((resp) =>
              SuperAdminActions.GetUserSpotlightResponses.Success({ spotlightResponses: resp })
            ),
            catchError((error) =>
              of(SuperAdminActions.GetUserSpotlightResponses.Fail({ error }))
            )
          )
      )
    )
  );

  linkUserWithSpotlightResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.LinkUserWithSpotlight.Request),
      mergeMap((action) =>
        this.superAdminService
          .linkUserWithSpotlightResponse(action.userAK, action.spotlightResponseId)
          .pipe(
            mergeMap((resp) => [
              SuperAdminActions.LinkUserWithSpotlight.Success(),
              DisplaySnackbarAlert.SetAlert({
                alert: {
                  messageHeader: 'Success!',
                  message: `Successfully linked profile`,
                  alertType: 'success',
                },
              }),
            ]),
            catchError((error) =>
              of(SuperAdminActions.LinkUserWithSpotlight.Fail({ error }))
            )
          )
      )
    )
  );

  removeUserFromAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.RemoveUserFromAccount.Request),
      mergeMap(action =>
        this.superAdminService.removeUserFromAccount(action.accountId, action.userAk).pipe(
          map(() => SuperAdminActions.RemoveUserFromAccount.Success({ accountId: action.accountId, userAk: action.userAk })),
          catchError(error => of(SuperAdminActions.RemoveUserFromAccount.Fail({ error })))
        )
      )
    )
  );

  addMindflickCoachToAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.AddMindflickCoachToAccount.Request),
      mergeMap(action =>
        this.superAdminService.addMindflickCoachToAccount(action.userAk, action.accountId).pipe(
          map((resp) => SuperAdminActions.AddMindflickCoachToAccount.Success({ resp })),
          catchError(error => of(SuperAdminActions.AddMindflickCoachToAccount.Fail({ error })))
        )
      )
    )
  );

  getMindflickOrganisations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.GetMindflickOrganisations.Request),
      mergeMap(action =>
        this.superAdminService.getMindflickOrganisations().pipe(
          map((resp) => SuperAdminActions.GetMindflickOrganisations.Success({ resp })),
          catchError(error => of(SuperAdminActions.GetMindflickOrganisations.Fail({ error })))
        )
      )
    )
  );

  getMindflickOrganisationUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.GetMindflickOrganisationUsers.Request),
      mergeMap(action =>
        this.superAdminService.getMindflickOrganisationUsers(action.organisationId).pipe(
          map((resp) => SuperAdminActions.GetMindflickOrganisationUsers.Success({ resp })),
          catchError(error => of(SuperAdminActions.GetMindflickOrganisationUsers.Fail({ error })))
        )
      )
    )
  );

  setMindflickOrganisationAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.SetMindflickOrganisationAdmin.Request),
      mergeMap(action =>
        this.superAdminService.setMindflickOrganisationAdmin(action.organisationId, action.userAk, action.updateAccountAdmins).pipe(
          mergeMap((resp) => {

            this.modalService.hide(action.modalId);

            return [
              SuperAdminActions.SetMindflickOrganisationAdmin.Success({
                organisationId: action.organisationId,
                userAk: action.userAk,
                adminName: resp.adminName,
                updatedAccountIds: resp.updatedAccountIds
              }),
              LayoutActions.DisplaySnackbarAlert.SetAlert({ alert: { message: 'You have successfully updated the organisation admin!', alertType: 'success' } })
            ];
          }),
          catchError(error => of(SuperAdminActions.SetMindflickOrganisationAdmin.Fail({ error })))
        )
      )
    )
  );

  setMindflickOrganisationLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.SetMindflickOrganisationLead.Request),
      mergeMap(action =>
        this.superAdminService.setMindflickOrganisationLead(action.organisationId, action.userAk).pipe(
          mergeMap((resp) => {
            this.modalService.hide(action.modalId);
            return [SuperAdminActions.SetMindflickOrganisationLead.Success({ organisationId: action.organisationId, userAk: action.userAk, leadName: resp.leadName }), LayoutActions.DisplaySnackbarAlert.SetAlert({ alert: { message: 'You have successfully updated the organisation lead!', alertType: 'success' } })
];
          }),
          catchError(error => of(SuperAdminActions.SetMindflickOrganisationLead.Fail({ error })))
        )
      )
    )
  );

  updateSeatCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.UpdateSeatCount.Request),
      mergeMap(action =>
        this.superAdminService.updateSeatCount(action.model).pipe(
          mergeMap((resp) => {
            this.modalService.hide(action.modalRef)

            return [SuperAdminActions.UpdateSeatCount.Success({ account: resp, orgId: action.orgId }), LayoutActions.DisplaySnackbarAlert.SetAlert({ alert: { message: `Seat count updated to ${resp.seats}!`, alertType: 'success' } })
];
          }),
          catchError(error => of(SuperAdminActions.UpdateSeatCount.Fail({ error })))
        )
      )
    )
  );

  addOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.CreateOrganisation.Request),
      mergeMap(action =>
        this.superAdminService.addOrg(action.model).pipe(
          map((resp) => {

            this.modalService.hide(action.modalRef);
            return SuperAdminActions.CreateOrganisation.Success({ org: resp });
          }),
          catchError(error => of(SuperAdminActions.CreateOrganisation.Fail({ error })))
        )
      )
    )
  );

  addUserToQuestionnaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminActions.AddUserToQuestionnaire.Request),
      mergeMap(action =>
        this.superAdminService.addUserToQuestionnaireFeedback(action.spotlightResponseId, action.model).pipe(
          map((resp) => {
            return SuperAdminActions.AddUserToQuestionnaire.Success({ resp: resp });
          }),
          catchError(error => of(SuperAdminActions.AddUserToQuestionnaire.Fail({ error })))
        )
      )
    )
  );

  private addUserAlert(amountAdded: number, amountNotAdded: number, amountDeactivated: number) {

    var addedMessage = amountAdded ? `You added ${amountAdded} user(s). ` : '';
    var notAddedMessage = amountNotAdded ? `Failed to add ${amountNotAdded} user(s). ` : '';
    var deactivatedMessage = amountDeactivated ? `There were ${amountDeactivated} deactivated user(s). ` : '';
    var message = addedMessage + deactivatedMessage + notAddedMessage;

    var alertType: 'info' | 'success' | 'danger' = notAddedMessage || amountDeactivated ? 'info' : amountAdded ? 'success' : 'danger';
    var messageHeader = notAddedMessage || amountDeactivated ? 'Info' : amountAdded ? 'Success!' : 'Failure';

    return LayoutActions.DisplaySnackbarAlert.SetAlert({
      alert: {
        alertType,
        messageHeader,
        message
      },
    });
  }

  constructor(
    private actions$: Actions,
    private superAdminService: SuperAdminService,
    private modalService: BsModalService
  ) {}
}
