<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
           data-cy="close"
  ></fa-icon>
</div>
<div class="modal-body">
    <h2 class="mb-4">Edit Organisation Admin</h2>
    <div class="col-12">
      <label for="account-lead" class="form-label">Organisation Admin</label>
      <app-dropdown-select [options]="users"
                           placeholder="Please select"
                           [(ngModel)]="selectedUserAk"
                           data-cy="admin"
                           ></app-dropdown-select>
    </div>
    <div class="col-12 mt-0">
      <hr />
        <div class="d-flex p-0 mt-1 justify-content-around">
          <div class="col-11">
            <label for="isConnectionRequired"
                   class="form-label d-flex justify-content-between">
              Update Account Admins
            </label>
            <p class="body-copy">Change any Account Admins that match the current Organisation Admin to the new Organisation Admin.</p>
          </div>
          <input
                 [(ngModel)]="updateAccountAdmins"
                 class="form-check-input me-1"
                 type="checkbox"
                 data-cy="account-check"
                 />
        </div>
      </div>
    <button class="btn btn-purple mt-4 w-100" (click)="saveChanges()" data-cy="submit">Save Changes</button>
</div>
<div class="modal-bottom-border"></div>
