import { Component, Input } from '@angular/core';
import { LiveAnalyticsProperty } from '../../../shared/models/live-analytics/live-analytics-graph.interface';

interface MultiData {
  name: string;
  series: LiveAnalyticsProperty[];
}

@Component({
  selector: 'app-la-summary-card',
  templateUrl: './la-summary-card.component.html',
  styleUrl: './la-summary-card.component.scss'
})
export class LaSummaryCardComponent {

  @Input() title = 'Total Users';

  @Input() count = 0;
}
