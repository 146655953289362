<div
  *ngIf="id"
  class="work-on-card position-relative d-flex flex-column justify-content-end"
  [class.margin-right]="hasRightMargin"
  [ngStyle]="{
    'min-width': minWidthPx + 'px',
    width: widthVw ? widthVw + 'vw' : '',
     height: isSmall ? '70px' : 'auto'
  }"
     [style.background-image]="'linear-gradient(rgba(47, 47, 47, 0) 0%, rgba(3, 3, 3, 0.655) 37%, rgba(0, 0, 0, 0.7) 100%), url(' + encodedImageUri + ')'"
     (click)="showWorkOn()"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()">
  <div *ngIf="isUpcoming" class="remove justify-content-end">
    <fa-icon
      class="cursor-pointer fa-lg"
      [icon]="close"
      (click)="remove(); $event.stopPropagation()"
    ></fa-icon>
  </div>
  <div *ngIf="growthType == GrowthType.WorkOn" class="text-grey body-copy mb-1">
    <ng-container *ngIf="workOnType == WorkOnType.MicroAction">
      MICRO-ACTION
    </ng-container>
    <ng-container *ngIf="workOnType == WorkOnType.Learn"> LEARN </ng-container>
  </div>
  <h3 class="text-white mb-1">{{ title }}</h3>
  <div *ngIf="showSummary" class="body-copy text-white summary">
    <ng-template [nestedEllipsis]>{{ summary }}</ng-template>
  </div>
  <div
    *ngIf="canPin && isHovering"
    class="position-absolute pin-container d-flex align-items-start justify-content-end"
  >
    <fa-icon
      class="text-white fs-2 cursor-pointer"
      [icon]="pin"
      (click)="onPinClicked($event)"
    ></fa-icon>
  </div>
</div>
