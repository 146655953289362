<div class="graph-container">
    <h2>Team views</h2>
    <h3 class="mt-3">Number of times users opened a team page</h3>
    <div class="mt-3">
        <ngx-charts-bar-horizontal *ngIf="!resultsLoading" [results]="results"
            [scheme]="horizontalBarScheme"
            [xAxis]="true"
            [yAxis]="true"
            [yAxisLabel]="yAxisLabel"
            [xAxisTickFormatting]="formatBarXAxis"
            [xAxisLabel]="'Profile views'"
            [legend]="false"
            [legendTitle]="''"
            [legendPosition]="LegendPosition.Below"
            [gradient]="true">
        </ngx-charts-bar-horizontal>
        <div *ngIf="resultsLoading" class="d-flex align-items-center justify-content-center w-100 loader">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
