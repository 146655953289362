import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectValue } from 'src/app/shared/models';

@Component({
  selector: 'app-toggle-options',
  templateUrl: './toggle-options.component.html',
  styleUrl: './toggle-options.component.scss'
})


export class ToggleOptionsComponent {
  _selected: any = 0;
  @Input()
  get selected() {
    return this._selected;
  }
  set selected(val: any) {
    if (this._selected !== val) {
      this.updateSlider(val);
    }

    this._selected = val;
  }

  @Input()
  options: SelectValue[] = [
    { value: 0, description: 'Option 1' },
    { value: 1, description: 'Option 2' },
    { value: 2, description: 'Option 3' }
  ];


  @Output() selectedChange = new EventEmitter<any>();

  sliderPositionPercent: number = 0;

  
  onOptionClick(value: any): void {
    this.updateSlider(value);
    this.selected = value;
    this.selectedChange.emit(value);
  }

  updateSlider(value: any) {
    const valueIndex = this.options.findIndex(o => o.value === value);

    if (valueIndex !== -1)
      this.sliderPositionPercent = (valueIndex * 100) / this.options.length;
  }
}
